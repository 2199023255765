import React from "react";
import Navbar from "./Header/Navbar";
import AdminNavbar from "./AdminHeader/Navbar";
import Footer from "./Footer";
import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { getAuth } from "../../application/reducers/authSlice";

const Layout = ({ children }) => {
  const authState = useSelector(getAuth);

  return (
    <>
      {authState.role === "admin" ? <AdminNavbar /> : <Navbar />}

      <AnimatePresence>{children}</AnimatePresence>
      <Footer />
    </>
  );
};

export default Layout;
