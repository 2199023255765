import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";
import mobileHandImage from "../../Assets/products/mobilehand.svg";

const useStyles = makeStyles((theme) => ({
  sliderDiv: {
    position: "relative",
    width: "35%",
    margin: "auto",
    [theme.breakpoints.down(950)]: {
      width: "60%",
      marginBottom: "7rem",
    },
  },
  arrow: {
    position: "absolute",
    cursor: "pointer",
    zIndex: 10,
    "&>svg": {
      fontSize: "2rem",
      color: "gray",
    },
    [theme.breakpoints.down(670)]: {
      display: "none",
    },
  },
  slickUl: {
    "& li": {
      width: 30,
      height: 30,
      transition: "transform 0.5s",
      filter: "grayscale(70%)",
    },
    "& li.slick-active": {
      transform: "scale(1.25)",
      filter: "grayscale(0%)",
    },
  },
}));

const ProductsCarousel = ({ children, className }) => {
  const classes = useStyles();
  const sliderRef = useRef();

  const settings = {
    arrows: false,
    dots: true,
    // fade: true,
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 5000,
    // pauseOnHover: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          bottom: "-3.5rem",
        }}
      >
        <ul style={{ margin: 0, padding: 0 }} className={classes.slickUl}>
          {dots}
        </ul>
      </div>
    ),
    customPaging: function (i) {
      return (
        <img
          style={{ width: "100%", height: "100%", display: "block" }}
          src={mobileHandImage}
          alt=""
        />
      );
    },
  };

  return (
    <div className={classes.sliderDiv + " " + className}>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default ProductsCarousel;
