import { Box } from "@material-ui/core";
import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Layout from "../component/Layout";

import Home from "../pages";
import AboutUs from "../pages/about-us";
import Career from "../pages/career";
import Clients from "../pages/clients";
import ContactUs from "../pages/contact-us";
import Details from "../pages/details";
import Faq from "../pages/faq";
import Location from "../pages/location";
import Login from "../pages/login";
import OurTeam from "../pages/our-team";
import Products from "../pages/products";
import Profile from "../pages/profile";
import PrivacyPolicy from "../pages/privacyPolicy";
import Payment from "../pages/payment";
import Features from "../pages/features";

import AdminHome from "../pages/admin";
import AdminCode from "../pages/admin/code";
import AdminPhone from "../pages/admin/phone";

const routes = [
  {
    path: "/",
    guard: false,
    component: Home,
  },
  {
    path: "/products",
    guard: false,
    component: Products,
  },
  {
    path: "/career",
    guard: false,
    component: Career,
  },
  {
    path: "/clients",
    guard: false,
    component: Clients,
  },
  {
    path: "/contact-us",
    guard: false,
    component: ContactUs,
  },
  {
    path: "/faq",
    guard: false,
    component: Faq,
  },
  {
    path: "/about-us",
    guard: false,
    component: AboutUs,
  },
  {
    path: "/features",
    guard: false,
    component: Features,
  },
  {
    path: "/our-team",
    guard: false,
    component: OurTeam,
  },
  {
    path: "/login",
    guard: false,
    component: Login,
  },
  {
    path: "/profile",
    guard: true,
    component: Profile,
  },
  {
    path: "/details",
    guard: false,
    component: Details,
  },
  {
    path: "/location",
    guard: false,
    component: Location,
  },
  {
    path: "/admin",
    guard: true,
    role: "admin",
    component: AdminHome,
  },
  {
    path: "/admin/code",
    guard: true,
    role: "admin",
    component: AdminCode,
  },
  {
    path: "/admin/phone",
    guard: true,
    role: "admin",
    component: AdminPhone,
  },
  {
    path: "/privacy-policy",
    guard: false,
    component: PrivacyPolicy,
  },
  {
    path: "/payment",
    guard: false,
    component: Payment,
  },
];

class Routes extends React.Component {
  state = { hasError: false, error: "" };

  componentDidCatch(error, info) {
    console.log(info.componentStack);
    this.setState({
      hasError: true,
      error: `TypeError: ${error.message}`,
    });
  }

  render() {
    if (this.state.hasError) {
      return "Error!";
    }

    return (
      <Layout>
        <Switch>
          {routes.map((route) => (
            <ProtectedRoute
              exact
              key={route.path}
              role={route.role}
              {...route}
            />
          ))}
          <Route>
            <Box
              width="100vw"
              height="calc(100vh - 6rem)"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <strong style={{ fontSize: "1.1rem" }}>404&nbsp;</strong> Page Not
              Found.
            </Box>
          </Route>
        </Switch>
      </Layout>
    );
  }
}

export default Routes;
