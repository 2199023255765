import authReducer from "./authSlice";
import uiReducer from "./uiSlice";
import userReducer from "./userSlice";
import adminReducer from "./adminSlice";
import paymentReducer from "./paymentSlice";

const reducers = {
  auth: authReducer,
  ui: uiReducer,
  user: userReducer,
  admin: adminReducer,
  payment: paymentReducer,
};

export default reducers;
