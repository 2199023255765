import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideDrawer from "./SideDrawer/index";
import { Container } from "../UI/styledItems";
import DropLink from "./DropLink";
import { getAuth, logout } from "../../../application/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  navbar: {
    fontFamily: "'Poppins', sans-serif",
    background: "#1C232D",
    padding: "0 !important",
    position: "relative",
    zIndex: 200,
  },
  navbarCont: {
    height: "4.5rem",
    display: "flex",
    padding: "0 15px",
    alignItems: "center",
    position: "relative",
  },
  brandLogoLink: {
    marginRight: "0.6rem",
    [theme.breakpoints.down(430)]: {
      marginRight: "0.2rem",
    },
    "& img": {
      height: "4.25rem",
    },
  },
  brandName: {
    marginRight: "auto",
    color: "#CF8B01",
    fontSize: "1.5rem",
    fontWeight: 600,
    textDecoration: "none",
    [theme.breakpoints.down(700)]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down(430)]: {
      fontSize: "1.1rem",
    },
  },
  link: {
    color: "white",
    textDecoration: "none",
    transition: "color 0.5s",
    fontWeight: 500,
    margin: "0 1rem",
    cursor: "pointer",
    "&:hover": {
      color: "#58B63A",
    },
  },
  activeLink: {
    color: "#58B63A",
  },

  button: {
    margin: "0 0.4rem",
    padding: "0.75rem 0",
    textAlign: "center",
    width: "7rem",
    borderRadius: "5px",
    textDecoration: "none",
    transition: "background 0.5s, color 0.5s",
    fontWeight: 500,
  },

  signin: {
    color: "white",
    border: "1px solid #58B63A",
    background: "#58B63A",
  },

  signup: {
    color: "white",
    border: "1px solid white",
    background: "transparent",
    "&:hover": {
      background: "#58B63A",
      borderColor: "#58B63A",
    },
  },
  activeLinkButton: {
    background: "#58B63A",
    borderColor: "#58B63A",
  },
}));

const links = [
  {
    link: "/",
    name: "Home",
  },
  {
    link: "/products",
    name: "Products",
  },
  {
    link: "/features",
    name: "Featuers",
  },
  {
    link: "/career",
    name: "Career",
  },
  // {
  //   link: "/clients",
  //   name: "Clients",
  // },
  // {
  //   link: "/top-performer",
  //   name: "Top Performer",
  // },
  {
    link: "/contact-us",
    name: "Contact Us",
  },
  // {
  //   link: "/profile",
  //   name: "Profile",
  // }, 
  // {
  //   link: "/faq",
  //   name: "FAQ",
  // },
];

const Navbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet_Mobile = useMediaQuery(theme.breakpoints.down(1260));

  const authState = useSelector(getAuth);
  const dispatch = useDispatch();

  return (
    <div className={classes.navbar}>
      <Container className={classes.navbarCont}>
        <NavLink to="/" className={classes.brandLogoLink}>
          <img
            src={require("../../Assets/moreLogo.png").default}
            alt="Brand Logo"
          />
        </NavLink>
        <NavLink className={classes.brandName} to="/">
          More & High
        </NavLink>
        {isTablet_Mobile ? (
          <SideDrawer />
        ) : (
          <>
            {links.map((link, i) => (
              <NavLink
                key={i}
                className={classes.link}
                to={link.link}
                activeClassName={classes.activeLink}
              >
                {link.name}
              </NavLink>
            ))}

            <DropLink
              className={classes.link}
              links={[
                {
                  link: "/about-us",
                  name: "About Us",
                },
                {
                  link: "/our-team",
                  name: "Our Team",
                },
              ]}
              activeClassName={classes.activeLink}
            >
              Company
            </DropLink>
            {!authState.isAuth ? (null) : (
              <NavLink
                className={classes.link}
                to="/profile"
                activeClassName={classes.activeLink}
              >
                Profile
              </NavLink>
            )}
            <div style={{ width: "2rem" }}></div>

            {authState.isAuth ? (
              <p
                className={`${classes.button} ${classes.signup}`}
                onClick={() => dispatch(logout())}
              >
                Log Out
              </p>
            ) : (
              <NavLink
                className={`${classes.button} ${classes.signin}`}
                to="/login"
              >
                Log In
              </NavLink>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Navbar;
