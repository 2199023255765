import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  inputDiv: {},
  input: {
    fontFamily: "inherit",
    width: "100%",
    height: "3rem",
    color: "#1C232D",
    border: "none",
    outline: "none",
    boxShadow:
      "inset 3px 3px 6px 0px rgba(0, 0, 0, 0.25), inset -3px -3px 6px #FFFFFF",
    background: "#F0F0F8",
    borderRadius: 5,
    padding: "0 1.2rem",
  },
  textarea: {
    paddingTop: "1rem",
    height: "5rem",
    resize: "none",
    paddingBottom: "1rem",
  },
  helperP: {
    color: "rgba(28, 35, 45, 0.65)",
    fontSize: 12,
    fontWeight: 400,
    margin: "5px 13px 0",
  },
}));

// label={fieldItem.label}
// name={fieldItem.name}
// type={fieldItem.type}
// value={values[fieldItem.name] || ""}
// onChange={handleChange}
// onBlur={handleBlur}
// error={errors[fieldItem.name] && touched[fieldItem.name]}
// helperText={
//   errors[fieldItem.name] && touched[fieldItem.name]
//     ? errors[fieldItem.name]
//     : ""
// }

const Input = ({
  className,
  error,
  label,
  helperText,
  name,
  type,
  value,
  onChange,
  onBlur,
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.inputDiv} ${className}`}>
      <input
        className={classes.input}
        type={type}
        placeholder={label}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <p className={classes.helperP}>{helperText}</p>}
    </div>
  );
};

const Textarea = ({
  className,
  error,
  label,
  helperText,
  name,
  type,
  value,
  onChange,
  onBlur,
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.inputDiv} ${className}`}>
      <textarea
        className={`${classes.input} ${classes.textarea}`}
        type={type}
        placeholder={label}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <p className={classes.helperP}>{helperText}</p>}
    </div>
  );
};

export { Input, Textarea };
