import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    justifContent: "center",
    alignItems: "center",
    display: "flex",
    minHeight: "100%",
  },
}));

export default function Loader({ ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress m={2} color="secondary" thickness={5} {...props} />
    </div>
  );
}
