import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#ffffffe8",
    backdropFilter: "blur(5px)",
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const PageLoading = ({ show }) => {
  const classes = useStyles();

  if (show)
    return (
      <div className={classes.root}>
        <CircularProgress style={{ color: "gray" }} thickness={5} />
      </div>
    );
  else return null;
};

export default PageLoading;
