import React, { useState } from "react";
import { makeStyles, styled } from "@material-ui/core";
import { Container, GrassImage } from "../component/UI/styledItems";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  Faq: {
    background: "#F0F0F8",
    position: "relative",
    paddingBottom: "6rem",
  },
  heading: {
    color: "#1C232D",
    margin: "0 0 4rem",
    fontSize: "1.7rem",
    fontWeight: 600,
    [theme.breakpoints.down(670)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "1.3rem",
    },
  },
  questionDiv: {
    display: "flex",
    flexDirection: "row",
  },
  question: {
    fontSize: "1.1rem",
    fontWeight: 600,
    color: "#0D1E27",
    margin: 0,
    width: "calc(100% - 3rem)",
    [theme.breakpoints.down(900)]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "0.95rem",
    },
  },
  svgImg: {
    width: 25,
    height: 25,
    margin: "3px 0 0 auto",
  },
  answer: {
    fontSize: "1rem",
    color: "#0D1E27",
    marginLeft: "1rem",
    marginTop: "1rem",
    lineHeight: 1.75,
    [theme.breakpoints.down(900)]: {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "0.9rem",
      marginLeft: 0,
      marginTop: "1rem",
    },
  },
}));

const QuestionBox = styled(motion.div)({
  background: "#F0F0F8",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
  padding: "1rem",
  overflow: "hidden",
  transition: "height 3s",
  "& + &": {
    marginTop: "1.5rem",
  },
});

const questions = [
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum sunt laboriosam dolor iure ab aliquid sequi aut, fugit officiis autem.",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus totam necessitatibus cum, libero autem illum, animi quibusdam nemo eaque pariatur consectetur excepturi vero tenetur ex repellat! Ex sit nihil, ipsam optio aut dolorem tenetur aliquam repellat possimus molestias ea!",
  },
];

const Faq = () => {
  const classes = useStyles();

  const [openedQuestion, _setOpenQuestion] = useState(0);
  const setOpenQuestion = (questionNum) => () => {
    if (questionNum === openedQuestion) _setOpenQuestion(-1);
    else _setOpenQuestion(questionNum);
  };

  return (
    <>
      <MetaTags title={"More & High - Frequently Asked Questions"} />
      <AnimatedPage>
        <div className={classes.Faq}>
          <Container>
            <h1 className={classes.heading}>
              Frequently Asked Questions (FAQs)
            </h1>
            {questions.map((question, i) => (
              <QuestionBox layout key={i} onClick={setOpenQuestion(i)}>
                <motion.div layout className={classes.questionDiv}>
                  <h4 className={classes.question}>{question.question}</h4>
                  <svg className={classes.svgImg}>
                    <path stroke="#0D1E27" strokeWidth="3px" d="M 0,10 20,10" />
                    <motion.path
                      animate={{
                        d:
                          i !== openedQuestion
                            ? "M 10,0 10,20"
                            : "M 0,10 20,10",
                      }}
                      stroke="#0D1E27"
                      strokeWidth="3px"
                    />
                  </svg>
                </motion.div>
                <motion.p
                  style={{
                    display: i === openedQuestion ? "block" : "none",
                  }}
                  animate={{ opacity: i === openedQuestion ? 1 : 0 }}
                  className={classes.answer}
                >
                  {question.answer}
                </motion.p>
              </QuestionBox>
            ))}
          </Container>
          <GrassImage />
        </div>
      </AnimatedPage>
    </>
  );
};

export default Faq;
