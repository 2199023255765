import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Container,
  GrassImage,
  Heading,
  Paragraph,
  SmallHeading,
  TeamsPaper,
} from "../component/UI/styledItems";
import Slider from "react-slick";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";

const useStyles = makeStyles((theme) => ({
  Client: {
    background: "#F0F0F8",
    position: "relative",
    paddingBottom: "6rem",
  },
  sliderCont: {
    position: "relative",
  },
  carButton: {
    height: "3.5rem",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    zIndex: 2,
    [theme.breakpoints.down(800)]: {
      height: "2.5rem",
    },
    [theme.breakpoints.down(420)]: {
      height: "2rem",
    },
  },
  teamItem: {
    backgroundImage: `url(/clients/carBack.svg)`,
    backgroundSize: "auto 100%",
    textAlign: "left",
    padding: "5rem 4rem 7rem",
    [theme.breakpoints.down(500)]: {
      padding: "5rem 2.5rem 7rem",
    },
    "& > h2": {
      margin: "0 0 1.5rem",
      fontWeight: 600,
      color: "#0D1E27",
      position: "relative",
      "& span:first-child": {
        position: "relative",
        zIndex: 1,
      },
      "& span:last-child": {
        position: "absolute",
        width: "100%",
        height: 18,
        background: "#FFD234",
        left: 0,
        bottom: 5,
      },
    },
    "& p": {
      margin: 0,
      fontWeight: 600,
      fontSize: "1.3rem",
      letterSpacing: "0.4px",
      wordSpacing: "1.5px",
      [theme.breakpoints.down(670)]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.down(400)]: {
        fontSize: "1.1rem",
      },
    },
  },
  clientImage: {
    width: "12rem",
    height: "12rem",
    display: "block",
    margin: "5rem auto 2.3rem",
    borderRadius: "100%",
    boxShadow: "0px 0px 23px rgba(0, 0, 0, 0.35)",
    overflow: "hidden",
    [theme.breakpoints.down(800)]: {
      width: "10rem",
      height: "10rem",
    },
    [theme.breakpoints.down(500)]: {
      width: "8rem",
      height: "8rem",
    },
  },
  location: {
    color: "#455A64",
    fontSize: "1.1rem !important",
    marginBottom: "2rem !important",
  },
  blockquote: {
    position: "relative",
    maxWidth: "45rem",
    margin: "auto",
    fontWeight: 600,
    fontSize: "1.3rem",
    letterSpacing: "0.4px",
    wordSpacing: "1.5px",
    lineHeight: 1.5,
    [theme.breakpoints.down(670)]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "1.1rem",
    },
    "&::before": {
      content: "''",
      position: "absolute",
      width: 55,
      height: 55,
      background: `url(/clients/quoteStart.svg)`,
      backgroundSize: "100% 100%",
      top: 0,
      left: 0,
      transform: "translate(-70%, -100%)",
      [theme.breakpoints.down(1055)]: {
        transform: "translate(-120%, -60%)",
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down(600)]: {
        transform: "translate(-100%, -60%)",
        width: 30,
        height: 30,
      },
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: 55,
      height: 55,
      background: `url(/clients/quoteEnd.svg)`,
      backgroundSize: "100% 100%",
      bottom: 0,
      right: 0,
      transform: "translate(70%, 100%)",
      [theme.breakpoints.down(1055)]: {
        transform: "translate(120%, 60%)",
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down(600)]: {
        transform: "translate(100%, 60%)",
        width: 30,
        height: 30,
      },
    },
  },
}));

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  speed: 500,
  swipeToSlide: true,
  slidesToScroll: 1,
  initialSlide: 0,
};

const Client = () => {
  const classes = useStyles();

  const sliderRef = useRef();

  function next() {
    if (sliderRef.current) sliderRef.current.slickNext();
  }
  function previous() {
    if (sliderRef.current) sliderRef.current.slickPrev();
  }

  return (
    <>
      <MetaTags title={"More & High - Clients"} />
      <AnimatedPage>
        <div className={classes.Client}>
          <Container>
            <div className={classes.sliderCont}>
              <img
                className={classes.carButton}
                src={require("../Assets/clients/carPrevButton.svg").default}
                alt="prev button"
                style={{
                  left: "10%",
                  transform: "translateY( -50% )",
                }}
                onClick={previous}
              />
              <Slider ref={sliderRef} {...settings}>
                {["", "", ""].map((item, i) => (
                  <div key={i}>
                    <TeamsPaper
                      className={classes.teamItem}
                      padding="2rem"
                      margin="0.5rem"
                    >
                      <Heading className={classes.heading}>
                        <span>Our Clients</span>
                        <span></span>
                      </Heading>
                      <Paragraph color="#455A64">
                        Our clients send us a bunch of smiles with our services
                        and we love them...
                      </Paragraph>
                      <div className={classes.clientImage}>
                        <img
                          style={{ width: "100%" }}
                          src={
                            require("../Assets/teamMembers/dummy.png").default
                          }
                          alt=""
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <SmallHeading color="#0D1E27" margin="0 0 0.5rem">
                          Rahul Soni
                        </SmallHeading>
                        <p className={classes.location}>Maharastra</p>
                        <blockquote
                          color="#0D1E27"
                          className={classes.blockquote}
                        >
                          Our clients send us a bunch of smiles with our
                          services and we love them... Lorem ipsum dolor sit
                          amet consectetur adipisicing elit. Quidem dicta
                          explicabo!
                        </blockquote>
                      </div>
                    </TeamsPaper>
                  </div>
                ))}
              </Slider>
              <img
                className={classes.carButton}
                src={require("../Assets/clients/carNextButton.svg").default}
                alt="next button"
                style={{ right: "10%", transform: "translateY( -50% )" }}
                onClick={next}
              />
            </div>
          </Container>
          <GrassImage />
        </div>
      </AnimatedPage>
    </>
  );
};

export default Client;
