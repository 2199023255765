const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const login = createAsyncThunk(
  "auth/login",
  async ({ phone, password }, { extra: api, dispatch }) => {
    const { data } = await api.login({
      phone,
      password,
    });

    return data;
  }
);

const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, { extra: api, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.refreshToken();
      return data;
    } catch (error) {
      dispatch(logout());
      return rejectWithValue("Failed to refresh token.");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: false,
    role: null,
    token: "",
    name: "",
    ui: {
      login: {},
      refreshToken: {},
    },
  },
  reducers: {
    logout: () => {
      // just so that we have an action type to dispatch in our root reducer.
    },
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.ui.login.loading = true;
      state.ui.login.error = null;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.ui.login.loading = false;

      state.isAuth = true;
      state.token = payload.token;
      state.name = payload.name;
      state.role = payload.role;
    },
    [login.rejected]: (state, action) => {
      state.ui.login.loading = false;
      state.ui.login.error = action.error.message;
    },
    [refreshToken.pending]: (state) => {
      state.ui.refreshToken.loading = true;
    },
    [refreshToken.fulfilled]: (state, { payload }) => {
      state.token = payload.token;
      state.ui.refreshToken.loading = false;
    },
    [refreshToken.rejected]: (state) => {
      state.ui.refreshToken.loading = false;
    },
  },
});

const getAuth = (state) => state.auth;
const getAuthUI = (state) => state.auth.ui;
const getAuthRefreshTokenUi = (state) => state.auth.ui.refreshToken;

const { logout } = authSlice.actions;

export {
  getAuth,
  getAuthUI,
  login,
  logout,
  refreshToken,
  getAuthRefreshTokenUi,
};

export default authSlice.reducer;
