import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  MenuItem,
  Select,
  styled,
} from "@material-ui/core";
import { useState } from "react";
import MetaTags from "../../component/MetaTags";
import { AnimatedPage } from "../../component/UI";
import { Container } from "../../component/UI/styledItems";
import { useDispatch } from "react-redux";
import { generateTokens } from "../../../application/reducers/adminSlice";
import { PAYMENT_TYPE } from "./utils/contants";

const useStyles = makeStyles((theme) => ({
  root: {},
  generateCode: {
    marginTop: 0,
    borderBottom: "3px solid #0D1E27",
    fontWeight: 500,
    fontSize: "1.5rem",
    display: "inline-block",
    color: "#0D1E27",
    [theme.breakpoints.down(501)]: {
      fontSize: "1.2rem",
      marginBottom: "2rem",
    },
  },
  input: {
    border: "1px solid #0D1E27",
    outline: "none",
    background: "transparent",
    borderRadius: 7,
    height: "2.5rem",
    fontFamily: "inherit",
    width: "100%",
    color: "#6A6A6A",
    fontWeight: 500,
    padding: "0 1rem",
    "&::-webkit-input-placeholder": {
      color: "gray",
      fontWeight: 400,
    },
    "&:-ms-input-placeholder": {
      color: "gray",
      fontWeight: 400,
    },
    "&::placeholder": {
      color: "gray",
      fontWeight: 400,
    },
  },
  errorP: {
    color: "red",
    fontSize: "0.8rem",
  },
}));

const BlackButton = styled(Button)((props) => ({
  background: "#0D1E27",
  color: "white",
  fontSize: "18px",
  fontWeight: 400,
  fontFamily: "inherit",
  textTransform: "none",
  padding: "0.5rem 0",
  width: 350,
  borderRadius: 0,
  marginTop: "1rem",
  [props.theme.breakpoints.down(501)]: {
    fontSize: "16px",
    width: "100%",
  },
  "&:disabled": {
    color: "#929292",
  },
  "&:hover": {
    background: "#0D1E27",
  },
}));

const GenerateCode = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [numberOfTokens, setNumberOfTokens] = useState("");
  const [inputError, setInputError] = useState("");
  const [genCodeState, setGenCodeState] = useState({
    loading: false,
    error: "",
  });

  const validateTokenInput = (value) => {
    if (inputError.length !== 0) setInputError("");

    if (!Number(value)) {
      return "Please enter a valid number.";
    }
    if (Number(value) > 9999) {
      return "Number should be less than 10,000.";
    }

    return null;
  };

  const [fileUrl, setFileUrl] = useState("");
  const handleGenerateTokens = async (e) => {
    console.log("here");
    try {
      const error = validateTokenInput(numberOfTokens);
      if (error) {
        setInputError(error);
        return;
      }

      setGenCodeState({
        loading: true,
        error: "",
      });

      const res = await dispatch(
        generateTokens({ numberOfTokens, type: tokenType })
      );

      setGenCodeState({
        loading: false,
        error: "",
      });
      setNumberOfTokens("");
      setFileUrl(res.payload.fileUrl);
    } catch (error) {
      console.log(error.message);
    }
  };

  const [tokenType, setTokenType] = useState("YEARLY");

  return (
    <>
      <MetaTags title={"Admin Generate Tokens"} />
      <AnimatedPage>
        <div style={{ background: "#F0F0F8" }}>
          <Container>
            {/* Just to download the file */}
            <div style={{ display: "none" }}>
              <iframe title="download file" src={fileUrl} />
            </div>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="2rem"
            >
              <h2 className={classes.generateCode}>
                Generate Activation Tokens
              </h2>

              <Select
                value={tokenType}
                onChange={(e) => setTokenType(e.target.value)}
              >
                <MenuItem value={PAYMENT_TYPE.yearly}>Yearly</MenuItem>
                <MenuItem value={PAYMENT_TYPE.five_yearly}>
                  Five Yearly
                </MenuItem>
              </Select>
            </Box>

            <input
              className={classes.input}
              type="text"
              placeholder="Number of tokens to generate."
              value={numberOfTokens}
              onChange={(e) => setNumberOfTokens(e.target.value)}
            />
            <p className={classes.errorP}>{inputError}</p>

            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <BlackButton
                disabled={genCodeState.loading}
                onClick={handleGenerateTokens}
                style={genCodeState.error ? { borderColor: "red" } : {}}
              >
                {genCodeState.loading ? (
                  <CircularProgress
                    thickness={6}
                    size={31}
                    style={{ color: "white" }}
                  />
                ) : (
                  "Generate"
                )}
              </BlackButton>
            </Box>
          </Container>
        </div>
      </AnimatedPage>
    </>
  );
};

export default GenerateCode;
