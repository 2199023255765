import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  styled,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
import PageLoading from "../component/UI/PageLoading";
import {
  Container,
  GrassImage,
  Heading,
  TeamsPaper,
} from "../component/UI/styledItems";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserProfile,
  lockPhone,
  unlockPhone,
  getNewImages,
  getNewLocation,
  playSiren,
  stopSiren,
  userSelectors,
} from "../../application/reducers/userSlice";
import { useHistory } from "react-router-dom";
import { getAuth, logout } from "../../application/reducers/authSlice";
import decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  Profile: {
    background: "#F0F0F8",
    position: "relative",
    paddingBottom: "6rem",
  },
  teamItem: {
    // backgroundImage: `url(/clients/carBack.svg)`,
    // backgroundSize: "auto 100%",
    textAlign: "center",
    padding: "5rem 4rem 7rem",
    [theme.breakpoints.down(500)]: {
      padding: "5rem 2.5rem 7rem",
    },
    "& > h2": {
      margin: "0 0 1.5rem",
      fontWeight: 600,
      color: "#0D1E27",
    },
  },
  heading: {
    color: "#0D1E27",
    fontSize: "2.5rem",
    fontWeight: 600,
    margin: "auto",
    [theme.breakpoints.down(670)]: {
      fontSize: "2rem",
      borderWidth: "3px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "1.7rem",
    },
    position: "relative",
    "& span:first-child": {
      position: "relative",
      zIndex: 1,
    },
    "& span:last-child": {
      position: "absolute",
      width: "100%",
      height: 18,
      background: "#FFD234",
      left: 0,
      bottom: 8,
    },
  },
  customerName: {
    display: "block",
    margin: "1rem 0 2rem",
    [theme.breakpoints.down(1060)]: {
      textAlign: "center",
    },
    [theme.breakpoints.down(645)]: {
      marginBottom: "3rem",
      textShadow: "0px 0px 3px #898989",
    },
  },
  profileGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    rowGap: "1rem",
    columnGap: "1rem",
    alignItems: "flex-start",
    width: 500,
    [theme.breakpoints.down(1060)]: {
      margin: "auto",
    },
    [theme.breakpoints.down(755)]: {
      width: "100%",
    },
    [theme.breakpoints.down(645)]: {
      gridTemplateColumns: "1fr",
      rowGap: "0.4rem",
      textAlign: "center",
      justifyContent: "center",
    },
    "& h4": {
      margin: 0,
      fontWeight: 600,
      letterSpacing: "0.4px",
      wordSpacing: "1.5px",
      fontSize: "1.1rem",
    },
    "& p": {
      margin: 0,
      fontWeight: 400,
      letterSpacing: "0.4px",
      wordSpacing: "1.5px",
      fontSize: "1rem",
      [theme.breakpoints.down(645)]: {
        marginBottom: "1rem",
      },
    },
  },
  buttonsGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    rowGap: "1rem",
    columnGap: "1rem",
    alignItems: "center",
    width: 600,
    marginLeft: "2rem",
    marginTop: "3rem",
    [theme.breakpoints.down(1060)]: {
      margin: "auto",
      marginTop: "3rem",
    },
    [theme.breakpoints.down(870)]: {
      width: "100%",
    },
    [theme.breakpoints.down(700)]: {
      gridTemplateColumns: "1fr",
    },
  },
  moreLogoImg: {
    position: "absolute",
    right: 40,
    bottom: 0,
    width: "200px",
    background: "#F0F0F8",
    [theme.breakpoints.down(1275)]: {
      top: 40,
      bottom: "auto",
    },
    [theme.breakpoints.down(1060)]: {
      display: "none",
    },
  },
}));

const BlackButton = styled(Button)((props) => ({
  background: "#0D1E27",
  boxShadow:
    "inset -2px -2px 16px rgba(0, 0, 0, 0.25), inset 2px 2px 17px rgba(0, 0, 0, 0.25), -3px -3px 6px #FFFFFF, 3px 3px 6px rgba(0, 0, 0, 0.25)",
  border: "3px solid white",
  borderRadius: 4,
  color: "white",
  fontSize: "18px",
  fontWeight: 400,
  fontFamily: "inherit",
  textTransform: "none",
  padding: "0.5rem 0",
  [props.theme.breakpoints.down(501)]: {
    fontSize: "16px",
  },
  "&:disabled": {
    color: "#929292",
  },
  "&:hover": {
    background: "#0D1E27",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const authState = useSelector(getAuth);
  const userProfile = useSelector(userSelectors.getProfileInfo);
  const userUiState = useSelector(userSelectors.getUserUi);

  const isLocked = userProfile?.locked;
  const isGettingLocation = userProfile?.gettinglocation;
  const isGettingImages = userProfile?.gettingimages;

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const { uid } = useMemo(() => decode(authState.token), [authState]);

  const showImages = () => history.push(`/details?show=image&uid=${uid}`);
  const showLocation = () => history.push(`/details?show=location&uid=${uid}`);

  return (
    <>
      <MetaTags title={"Profile"} />
      <AnimatedPage>
        <PageLoading
          show={userUiState.fetchUserProfile.loading && !userProfile}
        />
        <div className={classes.Profile}>
          <Container>
            <TeamsPaper
              className={classes.teamItem}
              padding="2rem"
              margin="0.5rem"
            >
              <Heading className={classes.heading}>
                <span>WELCOME</span>
                <span></span>
              </Heading>
              <Box textAlign="left" position="relative">
                <Heading className={classes.customerName}>
                  {userProfile?.name}
                </Heading>
                <div className={classes.profileGrid}>
                  <h4>Mobile Number&nbsp;:</h4>
                  <p>{userProfile?.phoneNumber}</p>
                  <h4>Email&nbsp;:</h4>
                  <p style={{ wordBreak: "break-all" }}>{userProfile?.email}</p>
                  {/* <h4>IMEI&nbsp;:</h4>
                  <p></p>
                  <h4>Android ID&nbsp;:</h4>
                  <p>{"543675455"}</p>
                  <h4>Mobile&nbsp;:</h4>
                  <p></p> */}
                  <h4>PIN&nbsp;:</h4>
                  <p>{userProfile?.pin}</p>
                </div>
                <div className={classes.buttonsGrid}>
                  <BlackButton
                    disabled={isLocked || userUiState.lockPhone.loading}
                    onClick={() => dispatch(lockPhone())}
                    style={
                      userUiState.lockPhone.error ? { borderColor: "red" } : {}
                    }
                  >
                    {userUiState.lockPhone.loading ? (
                      <CircularProgress
                        thickness={6}
                        size={31}
                        style={{ color: "white" }}
                      />
                    ) : isLocked ? (
                      "Locked"
                    ) : (
                      "Lock Phone"
                    )}
                  </BlackButton>
                  <BlackButton
                    disabled={!isLocked || userUiState.unlockPhone.loading}
                    onClick={() => dispatch(unlockPhone())}
                    style={
                      userUiState.unlockPhone.error
                        ? { borderColor: "red" }
                        : {}
                    }
                  >
                    {userUiState.unlockPhone.loading ? (
                      <CircularProgress
                        thickness={6}
                        size={31}
                        style={{ color: "white" }}
                      />
                    ) : isLocked ? (
                      "Unlock Phone"
                    ) : (
                      "Unlocked"
                    )}
                  </BlackButton>
                  <BlackButton onClick={() => dispatch(getNewImages())}>Get New Images</BlackButton>
                  <BlackButton onClick={() => dispatch(getNewLocation())}>Get New Location</BlackButton>
                  <BlackButton onClick={() => dispatch(playSiren())}>Play Siren</BlackButton>
                  <BlackButton onClick={() => dispatch(stopSiren())}>Stop Siren</BlackButton>
                  <BlackButton onClick={showImages}>Images</BlackButton>
                  <BlackButton onClick={showLocation}>Location</BlackButton>
                  <BlackButton onClick={() => dispatch(logout())}>
                    Logout
                  </BlackButton>
                </div>
                {/* <div className={classes.moreLogoImg}>
                  <img
                    style={{ width: "100%" }}
                    src={require("../Assets/moreLogo.png").default}
                    alt=""
                  />
                </div> */}
              </Box>
            </TeamsPaper>
          </Container>
          <GrassImage />
        </div>
      </AnimatedPage>
    </>
  );
};

export default Profile;
