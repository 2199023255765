import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell } from "./styledItems";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const AdminTables = ({ headData, children }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{ borderRadius: 0 }}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {headData.map((heading, i) => (
              <StyledTableCell key={i}>{heading}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminTables;
