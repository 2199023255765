import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as uiActions, ALERT_TYPES } from "./uiSlice";

const paymentSelectors = {
  getPaymentOrderUi: (state) => state.payment.ui,
};

const getPaymentOrder = createAsyncThunk(
  "payment/getPaymentOrder",
  async ({ type }, { extra: api }) => {
    const { data } = await api.getPaymentOrder(type);
    return data.order;
  }
);

const verifyPayment = createAsyncThunk(
  "payment/verifyPayment",
  async ({ payload }, { extra: api, dispatch }) => {
    const { data } = await api.verifyPayment(payload);

    dispatch(
      uiActions.showAlert({
        type: ALERT_TYPES.SUCCESS,
        message:
          "Payment done successfully. You will recieve token via mail and message shortly.",
      })
    );
    return data.success;
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    ui: {},
  },
  extraReducers: {
    [getPaymentOrder.pending]: (state) => {
      state.ui.error = false;
      state.ui.loading = true;
    },
    [getPaymentOrder.fulfilled]: (state) => {
      state.ui.loading = false;
    },
    [getPaymentOrder.rejected]: (state) => {
      state.ui.loading = false;
      state.ui.error = true;
    },
    [verifyPayment.pending]: (state) => {
      state.ui.error = false;
      state.ui.loading = true;
    },
    [verifyPayment.fulfilled]: (state) => {
      state.ui.loading = false;
    },
    [verifyPayment.rejected]: (state) => {
      state.ui.loading = false;
      state.ui.error = true;
    },
  },
});

export default paymentSlice.reducer;

const paymentActions = { ...paymentSlice.actions };

export { paymentActions, paymentSelectors, getPaymentOrder, verifyPayment };
