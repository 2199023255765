import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import {
  getAuth,
  getAuthRefreshTokenUi,
} from "../../application/reducers/authSlice";
import {
  actions as UiActions,
  ALERT_TYPES,
} from "../../application/reducers/uiSlice";
const { useSelector, useDispatch } = require("react-redux");
const { Route, Redirect } = require("react-router");

const ProtectedRoute = ({ guard, component: Component, role, ...props }) => {
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const refreshTokenUiState = useSelector(getAuthRefreshTokenUi);

  useEffect(() => {
    // if (guard && !auth.isAuth) {
    //   dispatch(
    //     UiActions.showAlert({
    //       type: ALERT_TYPES.INFO,
    //       message: "Please login to access profile pages.",
    //     })
    //   );
    // }

    if (role === "admin" && auth.role !== "admin") {
      dispatch(
        UiActions.showAlert({
          type: ALERT_TYPES.WARNING,
          message: "You do not have access to view admin routes.",
        })
      );
    }
  }, [auth.isAuth, auth.role, dispatch, guard, role]);

  if (refreshTokenUiState.loading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "70vh",
          background: "rgba(165, 165, 165, 0.9)",
        }}
      >
        <CircularProgress
          style={{ margin: "auto" }}
          size="2.3rem"
          thickness={6}
        />
      </div>
    );
  } else {
    if (guard && !auth.isAuth) {
      return <Redirect to="/login" />;
    }

    if (role === "admin" && auth.role !== "admin") {
      return <Redirect to="/login" />;
    }

    return (
      <Route exact {...props}>
        <Component />
      </Route>
    );
  }
};

export default ProtectedRoute;
