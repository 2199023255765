import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  sliderDiv: {
    position: "relative",
    background: "#DCF8D2",
    color: "#1C232D",
  },
  arrow: {
    position: "absolute",
    cursor: "pointer",
    zIndex: 10,
    "&>svg": {
      fontSize: "2rem",
      color: "gray",
    },
    [theme.breakpoints.down(670)]: {
      display: "none",
    },
  },
  prevArrow: {
    top: "50%",
    left: "3%",
    transform: "translateY( -50% ) rotate( 180deg )",
  },
  nextArrow: {
    top: "50%",
    right: "3%",
    transform: "translateY( -50% )",
  },
}));

const Carousel = ({ children }) => {
  const classes = useStyles();
  const sliderRef = useRef();

  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          bottom: "8%",
        }}
      >
        <ul style={{ margin: 0, padding: 0 }} className={classes.dotsUl}>
          {dots}
        </ul>
      </div>
    ),
  };

  function next() {
    if (sliderRef.current) sliderRef.current.slickNext();
  }
  function previous() {
    if (sliderRef.current) sliderRef.current.slickPrev();
  }

  return (
    <div className={classes.sliderDiv}>
      <div
        className={`${classes.arrow} ${classes.prevArrow}`}
        onClick={previous}
      >
        <ArrowForwardIosIcon style={{ transform: "translateX( 1.3rem )" }} />
        <ArrowForwardIosIcon />
      </div>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
      <div className={`${classes.arrow} ${classes.nextArrow}`} onClick={next}>
        <ArrowForwardIosIcon style={{ transform: "translateX( 1.3rem )" }} />
        <ArrowForwardIosIcon />
      </div>
    </div>
  );
};

export default Carousel;
