import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuth } from "../../../application/reducers/authSlice";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    color: "#0D1E27",
  },
  root: {
    fontFamily: "inherit",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: (locationData) => (locationData ? 600 : 250),
  },
  link: {
    color: "#001ABD",
    fontWeight: 500,
    textDecoration: "none",
  },
  image: {
    width: "4rem",
    height: "4rem",
    background: "gray",
    cursor: "pointer",
  },
  coordinates: {
    display: "grid",
    gridTemplateColumns: "1fr",
    rowGap: "1rem",
  },
  cordVal: {
    color: "#808081",
    marginLeft: 10,
  },
  imageFrame: {
    position: "fixed",
    zIndex: 400,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    background: "rgba(255, 255, 255, 0.8)",
    backdropFilter: "blur(5px)",
    padding: "2rem",
    overflow: "auto",
    [theme.breakpoints.down(500)]: {
      padding: "1rem",
    },
    "& img": {
      width: "70%",
      margin: "auto",
      boxShadow: "0 0 9px 0px #afafaf",
      [theme.breakpoints.down(700)]: {
        width: "80%",
      },
      [theme.breakpoints.down(600)]: {
        width: "90%",
      },
      [theme.breakpoints.down(500)]: {
        width: "100%",
      },
    },
  },
}));

const locationHeads = [
  {
    name: "S.No.",
    width: "none",
  },
  {
    name: "Date & Time",
    width: "none",
  },
  {
    name: "Location",
    width: "none",
  },
  {
    name: "",
    width: "none",
  },
];

const imageHeads = [
  {
    name: "S.No.",
    width: "2rem",
  },
  {
    name: "Date & Time",
    width: "none",
  },
  {
    name: "Image",
    width: "none",
  },
];

export default function CustomTable({ locationData, imagesData }) {
  const classes = useStyles(locationData ? true : false);

  const authState = useSelector(getAuth);

  const [selectedImage, setSelectedImage] = useState(null);
  const openImage = (image) => () => {
    setSelectedImage(image);
  };
  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      {selectedImage && (
        <div className={classes.imageFrame} onClick={closeImage}>
          <img src={selectedImage} alt="" />
        </div>
      )}
      <TableContainer
        component={Paper}
        style={{ minHeight: 152, maxHeight: "70vh" }}
      >
        <Table
          className={classes.table}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {(locationData ? locationHeads : imageHeads).map((cell, i) => (
                <StyledTableCell key={i} width={cell.width}>
                  {cell.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {locationData &&
              locationData.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell>
                    {new Date(row.timestamp).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className={classes.coordinates}>
                      <div>
                        <span>Latitude:</span>
                        <span className={classes.cordVal}>{row.latitude}</span>
                      </div>
                      <div>
                        <span>Longitude:</span>
                        <span className={classes.cordVal}>{row.longitude}</span>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <NavLink
                      to={`/location?lat=${row.latitude}&lng=${row.longitude}&name=${authState.name}`}
                      className={classes.link}
                    >
                      View on map
                    </NavLink>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {imagesData &&
              imagesData.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell>
                    {new Date(row.timestamp).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className={classes.image} onClick={openImage(row.url)}>
                      <img
                        style={{ width: 70, height: 70 }}
                        src={row.url}
                        alt=""
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
