import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Container, GrassImage } from "../component/UI/styledItems";
import clsx from "clsx";

import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#F0F0F8",
    position: "relative",
    paddingBottom: "6rem",
  },
  h1: {
    margin: 0,
    fontSize: "2rem",
    fontWeight: 600,
    color: "blue",
    textAlign: "center",
  },
  h2: {
    margin: 0,
    fontSize: "1.7rem",
    fontWeight: 600,
    marginBottom: "1rem",
    textAlign: "center",
  },
  h4: {
    margin: 0,
    fontSize: "1.3rem",
    fontWeight: 600,
    "& + p": {
      marginTop: "0.5rem",
    },
  },
  p: {
    margin: 0,
    fontSize: "1rem",
    fontWeight: 500,
    "& + h4": {
      marginTop: "2rem",
    },
    "& + &": {
      marginTop: "0.5rem",
    },
  },
  li: {
    margin: 0,
    fontSize: "1rem",
    fontWeight: 500,
    //  "& + h4": {
    //    marginTop: "2rem",
    //  },
  },
  strong: {
    fontWeight: 600,
    marginRight: 7,
  },
  span: {
    "& + ul": {
      marginTop: "0.75rem",
      marginBottom: "1rem",
    },
  },
  customUl: {
    "& strong": {
      display: "block",
    },
    "& li": {
      marginBottom: "1rem",
    },
  },
  table: {
    margin: "1rem 0 2rem",
    fontWeight: 500,
    border: "1px solid black",
  },
  tr: {},
  th: {
    fontWeight: 600,
    fontSize: "1.1rem",
    border: "1px solid black",
    padding: "0.5rem",
  },
  td: {
    fontSize: "1rem",
    border: "1px solid black",
    padding: "0.5rem",
  },
}));

const Client = () => {
  const classes = useStyles();

  return (
    <>
      <MetaTags title={"More & High - Clients"} />
      <AnimatedPage>
        <div className={classes.root}>
          <Container>
            <h1 className={classes.h1}>
              MORE AND HIGH TECHNOLOGIES PRIVATE LIMITED
            </h1>
            <h2 className={classes.h2}>PRIVACY POLICY</h2>

            <Box my={3}>
              <strong className={classes.strong}>
                Privacy Policy Statement on the Use of this Website
              </strong>
              <br />
              <em>Created on June 7, 2021</em>
            </Box>

            <h4 className={classes.h4}>Policy Statement</h4>
            <p className={classes.p}>
              More And High Technologies Private Limited understands that
              privacy and confidentiality is important to our users and
              customers. We will protect and preserve confidential information,
              and personal information will be used only for intended purpose.
              Your personal information will not be disclosed to any other
              organization or to any other individual without your prior consent
            </p>

            <h4 className={classes.h4}>Objective</h4>
            <p className={classes.p}>
              This privacy policy is made in order to safeguard and protect the
              privacy of our customers. Customer’s personal information is our
              prime concern. We fetch customers’ information to gain the
              understanding of their demands. The policy outlines what
              information we collect when you access our website or use our
              services and products. It also provides information about how we
              store, transfer, use, retain and delete that information and what
              choices you have with respect to the information. Company is
              committed to safeguarding the privacy of our users.
            </p>

            <h4 className={classes.h4}>Applicability</h4>
            <p className={classes.p}>
              This Privacy Policy applies to any data entered by customers on
              website or collected otherwise (for example, if you contact us
              directly via mail or telephone call). However, the policy does not
              apply to any information obtained by us through other websites.
              Our services and your use of this website are subject to the
              privacy policy.
            </p>

            <h4 className={classes.h4}>Information Required About You </h4>
            <p className={classes.p}>
              Company collects, retains, and use nonpublic personal information
              about customers to provide products and services to our customers.
              We may obtain the following categories of nonpublic personal
              information about you:
            </p>

            <ul className={classes.ul}>
              <li className={classes.li}>
                Information that we receive from you, through signing up or
                registration on website for accessing our services or products
              </li>
              <li className={classes.li}>
                Detail of your transactions with us.
              </li>
            </ul>

            <h4 className={classes.h4}>Collection</h4>
            <p className={classes.p}>
              Anyone can browse our Website without providing any personal
              information to us. However, if you wish to receive any
              notifications or updates from us, we may ask you for personally
              identifiable information, or "PII". This will be provided by the
              customer voluntarily, including
            </p>

            <ul className={classes.ul}>
              <li className={classes.li}>your name,</li>
              <li className={classes.li}>telephone number,</li>
              <li className={classes.li}>email address, </li>
              <li className={classes.li}>postal address,</li>
              <li className={classes.li}>gender</li>
              <li className={classes.li}>date of birth and</li>
              <li className={classes.li}>any other information.</li>
            </ul>

            <p className={classes.p}>
              Certain non-personally identifiable information, or non-PII, which
              does not identify you, may also be collected when you use this
              website.
            </p>

            <h4 className={classes.h4}>Types of personal data we collect</h4>
            <p className={classes.p}>
              When Company collects any personal information directly from you,
              we are the controller, and we are responsible for collection,
              processing and disclosure of your personal information in
              accordance with this privacy policy. Following are the types of
              information which we expressly ask you to enter and submit on our
              website, it collected by us directly from the customer
            </p>

            <ul className={classes.ul}>
              <li className={classes.li}>
                <strong className={classes.strong}>Contact Information:</strong>
                <span className={classes.span}>
                  If a customer shows an interest in collecting additional
                  information about our products and services or request
                  customer support or wishes to use our “Contact Us” or similar
                  features or wants to register to attend our seminars,
                  webinars, or other presentation or Sign up to use our website
                  or app, or wants to download any content then we require you
                  to provide us with your contact information, which may include
                  the following;
                </span>
                <ul className={classes.ul}>
                  <li className={classes.li}>Your name</li>
                  <li className={classes.li}>Interest</li>
                  <li className={classes.li}>Job title</li>
                  <li className={classes.li}>Address</li>
                  <li className={classes.li}>Phone number</li>
                  <li className={classes.li}>Email Address</li>
                  <li className={classes.li}>Username</li>
                  <li className={classes.li}>Password</li>
                </ul>
              </li>

              <li className={classes.li}>
                <strong className={classes.strong}>
                  Financial Information:
                </strong>
                <span className={classes.span}>
                  If a customer wants to buy or purchases or purchases any
                  product through our website or app, we may require you to
                  provide us with financial information and billing information
                  which may include the following;
                </span>
                <ul className={classes.ul}>
                  <li className={classes.li}>Billing name</li>
                  <li className={classes.li}>Billing address</li>
                  <li className={classes.li}>Credit or Debit card number</li>
                  <li className={classes.li}>Bank account number</li>
                  <li className={classes.li}>Other bank details</li>
                </ul>
              </li>

              <li className={classes.li}>
                <strong className={classes.strong}>
                  Device and Usage Information:
                </strong>
                <span className={classes.span}>
                  If a customer uses and interact with our website, we
                  automatically fetch the device information and usage
                  information, through common information gathering tools that
                  include the following;
                </span>
                <ul className={classes.ul}>
                  <li className={classes.li}>Log Files</li>
                  <li className={classes.li}>
                    Other information about computer
                  </li>
                  <li className={classes.li}>
                    Your usage of our websites through cookies
                  </li>
                  <li className={classes.li}>IP addresses</li>
                </ul>
              </li>

              <li className={classes.li}>
                <strong className={classes.strong}>Registration number:</strong>
                <span className={classes.span}>
                  If a customer register for any apps or web based community
                  online that we host or register to attend or wants to register
                  to attend seminars, webinars or other presentations conducted
                  by us we may ask for the information including;
                </span>
                <ul className={classes.ul}>
                  <li className={classes.li}>Username</li>
                  <li className={classes.li}>Photo</li>
                  <li className={classes.li}>Biographical Information</li>
                  <li className={classes.li}>Occupation</li>
                  <li className={classes.li}>Social media profiles</li>
                  <li className={classes.li}>Company name</li>
                  <li className={classes.li}>Areas of expertise</li>
                </ul>
              </li>

              <li className={classes.li}>
                <strong className={classes.strong}>User Submission:</strong>
                <span className={classes.span}>
                  If a customer post any personal information or other
                  information for any public use to our website or app, or any
                  web based online community hosted by us that you voluntarily
                  provide will be considered as you submissions. Such
                  information may include;
                </span>
                <ul className={classes.ul}>
                  <li className={classes.li}>Your tips</li>
                  <li className={classes.li}>Updates</li>
                  <li className={classes.li}>Alerts</li>
                  <li className={classes.li}>Comments</li>
                  <li className={classes.li}>Feedback</li>
                </ul>
              </li>

              <li className={classes.li}>
                <strong className={classes.strong}>Visitor Information:</strong>
                <span className={classes.span}>
                  If a customer visits our office, we require certain
                  information to be filled by a visitor, so you may be required
                  to provide the following information;
                </span>
                <ul className={classes.ul}>
                  <li className={classes.li}>Name</li>
                  <li className={classes.li}>Driving license </li>
                  <li className={classes.li}>Email address</li>
                  <li className={classes.li}>Phone number </li>
                  <li className={classes.li}>Company name</li>
                  <li className={classes.li}>
                    Time and date of arrival and exit
                  </li>
                </ul>
              </li>
            </ul>

            <h4 className={classes.h4}>Consent</h4>
            <p className={classes.p}>
              Company may also fetch information about you on visiting our
              website. Your web browser automatically sends certain information
              about you whenever you visit a website on internet; such
              information is called "Technical Information". Technical
              information cannot be used to identify a specific individual but
              it can be used to identify you indirectly. Our servers
              instinctively record the following information about you:
            </p>

            <h4 className={classes.h4}>Information Collected Automatically</h4>
            <p className={classes.p}>
              Company collects your personal information in connection with many
              of our services. While accessing our website, you may be prompted
              to make an account which may ask for personal information such as
              your name, mailing address, email address, or credit card
              information. Prior to collecting this information, we will obtain
              your consent. At any point of time, you can revoke consent and we
              will cease using your data immediately.
            </p>

            <ul className={classes.ul}>
              <li className={classes.li}>
                Your Internet Protocol ("IP") address,
              </li>
              <li className={classes.li}>
                Your browser type (Internet Explorer, Firefox, Opera, Google
                Chrome etc),{" "}
              </li>
              <li className={classes.li}>Browser language,</li>
              <li className={classes.li}>Location, </li>
              <li className={classes.li}>Your internet service providers </li>
              <li className={classes.li}>
                Operating system of your system and{" "}
              </li>
              <li className={classes.li}>Date and time of your request.</li>
            </ul>

            <p className={classes.p}>
              We may fetch your information automatically through
              below-mentioned ways
            </p>

            <ul className={classes.ul}>
              <li className={classes.li}>Email communication</li>
              <li className={classes.li}>Mobile communication</li>
              <li className={classes.li}>Cookies and Similar Technologies</li>
            </ul>

            <h4 className={classes.h4}>Disclosure of Personal Information</h4>
            <p className={classes.p}>
              We do not disclose any non public personal information about our
              customers or former customers to non affiliated third parties,
              except in following cases;
            </p>

            <ul className={clsx(classes.ul, classes.customUl)}>
              <li className={classes.li}>
                <strong className={classes.strong}>To Execute Requests</strong>
                <span className={classes.span}>
                  To fulfill your requests for products and services and
                  communicate with you about those requests;
                </span>
              </li>
              <li className={classes.li}>
                <strong className={classes.strong}>
                  To Understand Customer Behavior
                </strong>
                <span className={classes.span}>
                  To better understand customer behavior so as to achieve
                  improvement in our marketing and advertising efforts and to
                  improve the distribution of our products and services;
                </span>
              </li>
              <li className={classes.li}>
                <strong className={classes.strong}>
                  For Personalize Offerings
                </strong>
                <span className={classes.span}>
                  To help us personalize our service offerings, websites, mobile
                  services, and advertising;
                </span>
              </li>
              <li className={classes.li}>
                <strong className={classes.strong}>
                  For Legal Requirements
                </strong>
                <span className={classes.span}>
                  To comply with legal and/or regulatory requirements;
                </span>
              </li>
              <li className={classes.li}>
                <strong className={classes.strong}>
                  For Responding to Customer
                </strong>
                <span className={classes.span}>
                  To respond to reviews, comments, or other feedback you provide
                  us.
                </span>
              </li>
            </ul>

            <h4 className={classes.h4}>Advertising Messages</h4>
            <p className={classes.p}>
              Company may use your contact information to suggest you products
              and services that might excite you. We will send you marketing and
              advertising messages such as new sale or special offers or to
              notify you about our upcoming events. However, we also allow you
              to decline to receive the promotional messaging and advertising
              services.
            </p>

            <h4 className={classes.h4}>Communication Preferences</h4>
            <p className={classes.p}>
              Our website may send you advertising or promotional messages
              through mails or SMS. You can manage the receipt of promotional or
              advertising or non transactional or marketing communications from
              us by a click on the “Unsubscribe” link mentioned on the bottom of
              our mails and in case of SMS reply or text “STOP” to the received
              SMS communications. You can also turn off push notifications on
              our app from your device. Moreover, you can contact us and
              directly ask us to stop sending you marketing communication.
            </p>
            <p className={classes.p}>
              Please note that opting out of promotional messages or advertising
              communication will not stop us from sending you mails and SMS
              regarding important business communication like your subscription
              update, service announcements or sharing with you any security
              information.
            </p>

            <h4 className={classes.h4}>Data Storage and Retention </h4>
            <p className={classes.p}>
              We retain customer’s information only for business purposes and to
              provide you with our products and services as long as is
              reasonably necessary. Nevertheless, in no event the information
              will be retained for a period longer than twenty-four (24) months.
              We will also retain your information in order.
            </p>

            <ul className={classes.ul}>
              <li className={classes.li}>
                To comply with our legal obligations,
              </li>
              <li className={classes.li}>To resolve disputes and</li>
              <li className={classes.li}>To enforce our agreements.</li>
            </ul>

            <p className={classes.p}>
              We may also retain cached or archived copies of your information
              for a reasonable period of time, but in no event for longer than
              twenty-four (24) months. At any point of time, you can withdraw
              consent and we will immediately stop processing your data.
            </p>

            <p className={classes.p}>
              When use of personal information is no longer necessary for
              business purposes, we adopt a method to ensure that information is
              destroyed in a manner sufficient to prevent unauthorized access to
              that information.
            </p>

            <h4 className={classes.h4}>Location of Data Processing/Storage</h4>
            <p className={classes.p}>
              Any personal or non personal information collected or fetched by
              us about the customer through website or our services is processed
              in India by Company or by a third party acting on our behalf. When
              the customer provides his personal information to Company, he
              consents to processing his information in India. Our website is
              hosted in India.
            </p>

            <h4 className={classes.h4}>Use of Cookies</h4>
            <p className={classes.p}>
              Whenever you visit our site cookies will be created, they are
              small text files stored by a browser. It makes your online
              experience easier by saving browser information. There is no
              capacity in cookies to identify any personal information of an
              individual unless a person chooses to fill his information by
              signing up for an account or entering login information.
            </p>
            <p className={classes.p}>
              Company uses cookies to improve the website and simplify the
              interaction with customers. When a user or customer visits our
              website, our servers send a cookie to his device to help
              personalize better experience and advisements of your interests.
              Cookies enable us to better understand customer behavior and also
              facilitate us in providing effective advertisements. However,
              cookies does not provide us with any personally identifiable
              information of any customer or user. Web beacons are used
              primarily for providing the advertisements that are more of
              relevance to you.
            </p>

            <h4 className={classes.h4}>Option for disabling Technologies</h4>
            <p className={classes.p}>
              Company provides the customer with an option of not accepting the
              cookies. Any user or customer who wishes not to share any of his
              personal or non personal information with us can decline to accept
              cookies or disable other technologies.
            </p>

            <h4 className={classes.h4}>
              Consequences of Disabling Technologies
            </h4>
            <p className={classes.p}>
              On disabling the web browser’s cookies and other technologies,
              some features of our website along with few services will also get
              disabled but his is how customer will limit the functionality we
              provide when you visit our website.
            </p>

            <h4 className={classes.h4}>Information from Other Sources</h4>
            <p className={classes.p}>
              Company might fetch information about you from other sources and
              add it to our account information details.
            </p>
            <p className={classes.p}>
              By agreeing to use or agreeing to continue to use the website you
              also agree to our use of your information (including your personal
              information) in accordance with this Privacy policy, as may be
              amended from time to time by Company in its discretion. You also
              agree and give consent to us for collecting, storing, processing
              and sharing information (including personal information) related
              to you with third parties for the purposes as set out in this
              Privacy Policy.
            </p>
            <p className={classes.p}>
              It may be required by us to share the above mentioned information
              with agencies or government authorities in order to verify the
              identity or to prevent, detect or investigate incidents including
              of cyber incidents, prosecution and punishment of offences. You
              agree and give consent for Company to disclose your information,
              if so required, under applicable laws.
            </p>
            <p className={classes.p}>
              Following are the examples of information we may receive from
              other sources:
            </p>

            <ul className={classes.ul}>
              <li className={classes.li}>
                <strong className={classes.strong}>
                  Updated Delivery and Address Information-
                </strong>
                <span className={classes.span}>
                  This can be received from our carriers or other third parties,
                  which we use to correct the records, this helps us deliver
                  your next purchase more easily;
                </span>
              </li>
              <li className={classes.li}>
                <strong className={classes.strong}>
                  Account Information, Purchase Or Redemption Information-
                </strong>
                <span className={classes.span}>
                  account information, purchasing or redemption information and
                  page-view information from some merchants with which we
                  operate co-branded businesses or for which we provide
                  technical, fulfillment, advertising or other services;
                </span>
              </li>
              <li className={classes.li}>
                <strong className={classes.strong}>
                  Search Term And Search Result Information-
                </strong>
                <span className={classes.span}>
                  This information is received from some searches conducted
                  through the Web search features and
                </span>
              </li>
              <li className={classes.li}>
                <strong className={classes.strong}>
                  Credit History Information-
                </strong>
                <span className={classes.span}>
                  This information can be received from credit bureaus, which is
                  used to help in preventing and detecting fraud and to offer
                  certain credit or financial services to some customers.
                </span>
              </li>
            </ul>

            <h4 className={classes.h4}>
              Personal Data Obtained from Other Sources
            </h4>
            <p className={classes.p}>
              We may also obtain personal information about you from our
              affiliates and other third parties including the following;
            </p>

            <ul className={classes.ul}>
              <li className={classes.li}>
                Social networking sites when you grant us permission to access
                your data
              </li>
              <li className={classes.li}>
                Service providers that help to us determine your location
              </li>
              <li className={classes.li}>
                Businesses with which we can engage in marketing activities
              </li>
              <li className={classes.li}>Publicly available sources</li>
            </ul>

            <h4 className={classes.h4}>Your Privacy Rights</h4>
            <p className={classes.p}>
              Company gives you options about the ways we collect, use, and
              share your personal information. The information to be stored in
              your account and preferences will be of your choice. However, if
              you do not provide us with certain information, some of your
              preferences may get affected. You can request to access the
              information by contacting us if you wish to know what data we
              process about you.
            </p>

            <h4 className={classes.h4}>Advertisements on Website</h4>
            <p className={classes.p}>
              You might come across third-party advertising companies to serve
              ads while accessing our website. These companies may use
              information about your visit history in order to provide
              advertisements about their goods and services. However, your name,
              address, email address, or telephone number will be secured.
            </p>

            <h4 className={classes.h4}>Social Media Features</h4>
            <p className={classes.p}>
              Company may include links to Social Media websites, such as
              Facebook or Instagram or Twitter or any other social media sites.
              These Social Media websites may obtain your IP address, which page
              you are visiting on our website, and may set and create a cookie.
              Social Media websites are hosted by the third party. Our privacy
              policies also govern your interactions with the social media
              website.
            </p>

            <h4 className={classes.h4}>Links to Other Websites</h4>
            <p className={classes.p}>
              When a customer is on this website, he could be directed towards
              other sites that are beyond the control of Company. There may be
              links to other websites that take you outside our service such as,
              links to business partners and trade show companies. These other
              websites may send their own cookies to users, or otherwise collect
              data or seek personal information. If you are directed to any
              third-party website, we make no representation as to the
              existence, sufficiency, accuracy or completeness of the privacy
              policy of the companies associated with such websites. The cookies
              are sent to the user by the other websites they seek personal
              information and collect data.
            </p>

            <h4 className={classes.h4}>Third Parties or Business Partners</h4>
            <p className={classes.p}>
              Occasionally, Company may share non-personally identifiable
              information about our customers or users with our business
              partners. We also use or employ third party service providers such
              as an email service provider to send emails on the behalf of
              Company and Human resource Partner to process job applications.
              Other than our business partners, we do not provide personal or
              non-personal information to any third party for any purpose other
              than as defined in this Privacy Policy. We do not disclose any of
              the personal information until and unless it is legally required
              to do so, and it is done only if we feel the necessity of it as to
              protect our rights or to comply the judicial proceedings or for
              legal processing served on our website.
            </p>
            <p className={classes.p}>
              Similarly, our privacy policy does not allow to us sell any
              personal information that has been collected online without
              customers’ consent. It will be our responsibility to safeguard any
              of your personal information provided by you and we will also
              ensure to take reasonable steps in order to protect your
              information from third parties to whom we have provided your
              personal information online.
            </p>

            <h4 className={classes.h4}>Business / Assets Sale or Transfer</h4>
            <p className={classes.p}>
              Company may sell, transfer or share some or all of its assets and
              information, including your information in connection with a
              merger, acquisition, takeover, reorganization or sale of assets or
              in the event of bankruptcy. However in such case we will
              reasonably ensure that the information you have provided to us and
              information which we have collected and stored is used by the
              transferee subject to this privacy policy. Any party to which
              Company transfers or sell the information will have the right to
              continue to use the information you provide to us.
            </p>

            <h4 className={classes.h4}>
              How Secure Is your Information with us
            </h4>

            <ul className={classes.ul}>
              <li className={classes.li}>
                We ensure to protect the security of personal data during
                transmission by using Secure Sockets Layer (SSL) software, in
                addition to maintaining security and safeguarding privacy of
                your information.
              </li>
              <li className={classes.li}>
                We only reveal the last four digits of your credit card number
                while confirming your order so as to achieve privacy and
                securing your data. However, the entire credit card number is
                transmitted to the appropriate credit card company during
                processing of order.
              </li>
              <li className={classes.li}>
                We have established physical and electronic safeguards that are
                procedural in nature in connection with the collection, storage,
                retention and disclosure of personal information (including
                sensitive personal information). We may occasionally ask for
                proof of identity before we disclose personal information to
                you.
              </li>
              <li className={classes.li}>
                It is important for you too to protect against unauthorized
                access to your account or to your computer. Make sure to sign
                out when you finish using a shared computer.
              </li>
            </ul>

            <h4 className={classes.h4}>
              Security Measures to Protect Personal Information
            </h4>
            <p className={classes.p}>
              We ensure that our third party service providers and business
              partners agree to keep confidential all information we share with
              them and to use the information only to perform their obligations.
              These third-party service providers and business partners are
              expected to maintain privacy and security protections that are
              consistent with Company’s privacy and information security
              policies.
            </p>

            <h4 className={classes.h4}>Privacy related to Children</h4>
            <p className={classes.p}>
              Our website will not collect, use, retain or transfer any personal
              or non personal information from anyone under the age of 13
              (thirteen) for any purpose whatsoever. If a person is below 13,
              guardians consent prior to providing any personal information will
              be required.
            </p>
            <p className={classes.p}>
              Use of Company is available only to the individuals who are above
              18 years of age and who can form a legally binding contract under
              the Indian Contract Act, 1872. If a minor wish to access the site,
              he can do so only with the involvement of his guardian.
            </p>

            <h4 className={classes.h4}>Policy Compliance</h4>
            <p className={classes.p}>
              Compliance to the privacy policy shall be reviewed on a periodic
              basis to ensure continuous compliance, monitoring and
              implementation. Depending upon the situation, need for a revision
              to the policy may be identified.
            </p>

            <h4 className={classes.h4}>Policy update</h4>
            <p className={classes.p}>
              Company can amend privacy policy without giving any prior notice
              to any user. Privacy policy amendment is to reflect technological
              advancements, both legal and regulatory changes and better
              business practices. A new privacy policy amended by Company will
              show the changes in the updated document placed on the website.
            </p>
            <p className={classes.p}>
              We recommend that you must visit our site every time you provide
              your personal information to us.
            </p>

            <h4 className={classes.h4}>
              Disputes Resolution and Escalation Process for Customers
            </h4>
            <p className={classes.p}>
              Customers/third party with enquiries or complaints about the
              processing of their personal information shall bring the matter to
              the attention of company via mail or in writing.
            </p>

            <h4 className={classes.h4}>Glossary</h4>

            <table className={classes.table}>
              <thead>
                <tr className={classes.tr}>
                  <th className={classes.th}>Term</th>
                  <th className={classes.th}>Definition</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.tr}>
                  <td className={classes.td}>
                    Personally Identifiable Information (PII)
                  </td>
                  <td className={classes.td}>
                    Personally Identifiable Information (PII) PII is any
                    information about the person which can be used to identify
                    an individual that distinguishes him from any other
                    individual.
                  </td>
                </tr>
                <tr className={classes.tr}>
                  <td className={classes.td}>Third Party</td>
                  <td className={classes.td}>
                    Third Party All external parties- contractors, interns,
                    trainees, vendors who have access to information assets
                  </td>
                </tr>
                <tr className={classes.tr}>
                  <td className={classes.td}>Data protection and security</td>
                  <td className={classes.td}>
                    Information collected either personal or customer
                    information must be used lawfully and fairly, process it
                    only for limited purposes, use the information in an
                    adequate, relevant, accurate manner, use and retain
                    information for not longer than a period required, and
                    process the information in accordance with law, protect the
                    information and safeguard the privacy and not to transfer
                    the information without prior consent of the user.
                  </td>
                </tr>
                <tr className={classes.tr}>
                  <td className={classes.td}>Business Partners</td>
                  <td className={classes.td}>
                    The person who would be buying and selling the products from
                    Company
                  </td>
                </tr>
                <tr className={classes.tr}>
                  <td className={classes.td}>User/customer</td>
                  <td className={classes.td}>
                    Any legal person who uses or visits the website
                  </td>
                </tr>
                <tr className={classes.tr}>
                  <td className={classes.td}>Company/We/Us/Our</td>
                  <td className={classes.td}>
                    More And High Technologies Private Limited, a company
                    limited by shares
                  </td>
                </tr>
              </tbody>
            </table>

            <h4 className={classes.h4}>For More Information </h4>
            <p className={classes.p}>
              If you have any query regarding our Privacy Policy, or wish to
              receive a copy of the Policy, please contact us by writing to:
            </p>
            <p className={classes.p}>
              Mail id:{" "}
              <a
                style={{ color: "blue" }}
                href="mailto:Corporate@moreandhigh.com"
              >
                Corporate@moreandhigh.com
              </a>
            </p>
            <p className={classes.p}>
              Address: Office number 214, Krishna Business Centre Scheme no. 54,
              P.U - 4 Indore MP 452011 India
            </p>
          </Container>
          <GrassImage />
        </div>
      </AnimatedPage>
    </>
  );
};

export default Client;
