import React from "react";
import { styled, TableCell, TableRow, withStyles } from "@material-ui/core";

const Container = styled("div")((props) => ({
  width: "100%",
  maxWidth: "1320px",
  padding: "6rem",
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  background: props.background,
  [props.theme.breakpoints.down(1410)]: {
    maxWidth: "90%",
  },
  [props.theme.breakpoints.down(576)]: {
    maxWidth: "calc( 100% - 1.5rem )",
  },
}));

// eslint-disable-next-line jsx-a11y/heading-has-content
const Heading = styled(({ color, margin, ...other }) => <h2 {...other} />)(
  (props) => ({
    color: props.color,
    margin: props.margin,
    display: "inline-block",
    borderBottom: `4px solid ${props.color}`,
    fontSize: "2rem",
    fontWeight: 600,
    [props.theme.breakpoints.down(670)]: {
      fontSize: "1.7rem",
      borderWidth: "3px",
    },
    [props.theme.breakpoints.down(400)]: {
      fontSize: "1.5rem",
    },
  })
);

const Paragraph = styled(({ color, margin, ...other }) => <p {...other} />)(
  (props) => ({
    color: props.color,
    margin: props.margin,
    lineHeight: 1.7,
    fontSize: "1.3rem",
    fontWeight: 500,
    textTransform: "capitalize",
    [props.theme.breakpoints.down(670)]: {
      fontSize: "1.2rem",
    },
    [props.theme.breakpoints.down(400)]: {
      fontSize: "1.1rem",
    },
  })
);

// eslint-disable-next-line jsx-a11y/heading-has-content
const SmallHeading = styled(({ color, margin, ...other }) => <h4 {...other} />)(
  (props) => ({
    color: props.color,
    margin: props.margin,
    fontSize: "1.6rem",
    fontWeight: 600,
    [props.theme.breakpoints.down(670)]: {
      fontSize: "1.5rem",
    },
    [props.theme.breakpoints.down(400)]: {
      fontSize: "1.25rem",
    },
  })
);

const SmallParagraph = styled(({ color, margin, ...other }) => (
  <p {...other} />
))((props) => ({
  color: props.color,
  margin: props.margin,
  lineHeight: 1.6,
  fontSize: "1.2rem",
  fontWeight: 500,
  [props.theme.breakpoints.down(670)]: {
    fontSize: "1.05rem",
  },
  [props.theme.breakpoints.down(400)]: {
    fontSize: "0.98rem",
  },
}));

const Button = styled(({ notHover, color, hoverColor, ...other }) => (
  <div {...other} />
))({
  cursor: "pointer",
  display: "inline-block",
  padding: "0.3rem 1.3rem 0.5rem",
  borderRadius: "5px",
  fontWeight: 600,
  fontSize: "1.1rem",
  color: (props) => props.color,
  border: (props) => `2px solid ${props.color}`,
  background: "transparent",
  transition: "background 0.2s, color 0.2s",
  "&:hover": {
    color: (props) => (props.notHover ? props.color : props.hoverColor),
    background: (props) => (props.notHover ? "transparent" : props.color),
  },
});

const TeamsPaper = styled(
  ({ borderRadius, margin, padding, dimension, size, ...other }) => (
    <div {...other} />
  )
)({
  padding: (props) => props.padding,
  margin: (props) => props.margin,
  width: (props) => props.dimension,
  height: (props) => props.dimension,
  display: (props) => (props.dimension ? "flex" : "block"),
  textAlign: "center",
  borderRadius: (props) =>
    props.borderRadius
      ? props.borderRadius
      : props.size === "small"
      ? "5px"
      : "7px",
  boxShadow: (props) =>
    props.size === "small"
      ? "2px 2px 5px 0px rgb(0,0,0,0.33), -3px -3px 5px 2px rgba(255,255,255)"
      : "5px 5px 5px 0px rgb(0,0,0,0.33), -6px -6px 5px 0px rgba(255,255,255)",
});

const GrassImage = styled("div")((props) => ({
  position: "absolute",
  left: 0,
  bottom: 0,
  height: 115,
  width: "100%",
  background: `url(${require("../../Assets/grass.svg").default})`,
  backgroundSize: "auto 100%",
  backgroundPosition: "bottom",
  backgroundRepeat: "repeat-x",
  [props.theme.breakpoints.down(650)]: {
    height: 100,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    fontFamily: "inherit",
  },
  head: {
    backgroundColor: "#6A6A6A",
    color: "white",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#D9D9D9",
    "&:nth-of-type(odd)": {
      backgroundColor: "#EDEDED",
    },
  },
}))(TableRow);

export {
  Container,
  Heading,
  Paragraph,
  Button,
  TeamsPaper,
  SmallHeading,
  SmallParagraph,
  GrassImage,
  StyledTableCell,
  StyledTableRow,
};
