import { makeStyles } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropLink: {
    "& + div": {
      display: "none",
    },
    "&:hover": {
      "& + div": {
        display: "block",
      },
    },
  },
  dropdownLink: {
    position: "absolute",
    top: "2.3rem",
    transform: "translateX(-50%)",
    left: "50%",
    zIndex: 500,
    paddingTop: "0.75rem",
    marginTop: "-0.75rem",
    "&:hover": {
      display: "block",
    },
  },
  dropdownLinkCont: {
    padding: "12px 1rem",
    display: "flex",
    background: "#2c3645",
    borderRadius: 5,

    "& a": {
      whiteSpace: "nowrap",
    },
  },
  arrow: {
    position: "absolute",
    top: "2px",
    left: "50%",
    transform: "translateX(-50%)",

    width: "1.2rem",
  },
}));

const DropLink = ({ className, activeClassName, children, links, onClick }) => {
  const classes = useStyles();

  const linksStrArr = links.map((link) => link.link);
  const location = useLocation();

  return (
    <div className={classes.root}>
      <p
        className={`${className} ${
          linksStrArr.includes(location.pathname) && activeClassName
        } ${classes.dropLink}`}
      >
        {children}
      </p>
      <div className={classes.dropdownLink}>
        <div className={classes.dropdownLinkCont}>
          <img src="/icons/topArrow.svg" className={classes.arrow} alt="" />
          {links.map((link, i) => (
            <NavLink
              key={i}
              className={className}
              to={link.link}
              activeClassName={activeClassName}
              onClick={onClick}
            >
              {link.name}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropLink;
