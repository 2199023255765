import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, GrassImage } from "../component/UI/styledItems";
import FormikForm from "../component/FormikForm";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAuthUI, login } from "../../application/reducers/authSlice";
import { useHistory } from "react-router-dom";

const loginForm = [
  {
    label: "Your Phone Number",
    name: "phone",
    type: "text",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
  },
];

const LOGIN_INITIAL_VALUES = {
  phone: "",
  password: "",
};

const loginSchema = yup.object({
  phone: yup
    .string()
    .required()
    .matches(/^[6789]\d{9}$/, "Not a valid Mobile Number")
    .label("Mobile Number"),
  password: yup.string().required().label("Password"),
});

const useStyles = makeStyles((theme) => ({
  Login: {
    background: "#DCF8D2",
    position: "relative",
    paddingBottom: "6rem",
  },
  grid: {
    display: "grid",
    borderRadius: "1.25rem",
    boxShadow: "0 0 10px 1px #979797",
    background: "#F0F0F8",
    gridTemplateAreas: "'form design'",
    gridTemplateColumns: "5fr 5fr",
    [theme.breakpoints.down(723)]: {
      gridTemplateAreas: "'design' 'form'",
      gridTemplateColumns: "1fr",
    },
    "& > div": {
      height: 650,
      [theme.breakpoints.down(723)]: {
        height: "auto",
      },
    },
  },
  button: {
    [theme.breakpoints.down(700)]: {
      padding: "0.2rem 1rem 0.35rem",
      fontSize: "0.95rem",
    },
    [theme.breakpoints.down(723)]: {
      display: "none",
    },
  },
  design: {
    overflow: "hidden",
    position: "relative",
    zIndex: 10,
    gridArea: "design",
    backgroundColor: "#0D1E27",
    borderRadius: "0 1.25rem 1.25rem 0",
    backgroundImage: (props) =>
      `url(${require("../Assets/login/Login-img.png").default})`,
    backgroundRepeat: "round",
    [theme.breakpoints.down(723)]: {
      borderRadius: "1.25rem 1.25rem 0 0",
      height: "250px !important",
      backgroundImage: (props) =>
        `url(${require("../Assets/login/Login-img.png").default})`,
    },
  },
  paragraph: {
    fontSize: "1.2rem",
    color: "#f5f7ff",
    margin: "-0.4rem 0 2rem",
    fontWeight: 300,
    textTransform: "capitalize",
    lineHeight: 1.5,
    wordSpacing: 1.5,
    [theme.breakpoints.down("970")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.down("780")]: {
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    [theme.breakpoints.down(723)]: {
      margin: 0,
    },
  },
  form: {
    overflow: "hidden",
    padding: "4rem 0",
    gridArea: "form",
    background: "#F0F0F8",
    borderRadius: "1.25rem 0 0 1.25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(723)]: {
      borderRadius: "0 0 1.25rem 1.25rem !important",
      padding: "2rem 0",
    },
  },

  heading: {
    color: "#0D1E27",
    fontSize: "2rem",
    fontWeight: 800,
    margin: "auto 0 2rem",
    [theme.breakpoints.down("1200")]: {
      fontSize: "calc(1.4rem + 0.5vw)",
    },
    [theme.breakpoints.down("780")]: {
      fontSize: "1.42rem",
    },
    [theme.breakpoints.down(723)]: {
      marginBottom: "1rem",
    },
  },
}));

export default function Login() {
  const classes = useStyles();

  const authUiState = useSelector(getAuthUI);

  const history = useHistory();
  const dispatch = useDispatch();

  const submitLogin = async (values) => {
    const data = await dispatch(login({ ...values }));

    if (!data.payload) return;

    if (data.payload.role === "admin") {
      history.push("/admin");
    } else {
      history.push("/profile");
    }
  };

  return (
    <>
      <MetaTags title={"Login"} />
      <AnimatedPage>
        <div className={classes.Login}>
          <Container>
            <div className={classes.grid}>
              <div className={classes.design}></div>
              <div className={classes.form}>
                <h2 className={classes.heading}>Log In</h2>
                <FormikForm
                  label="Log In"
                  form={loginForm}
                  initialValues={LOGIN_INITIAL_VALUES}
                  schema={loginSchema}
                  onSubmit={submitLogin}
                  loading={authUiState.login.loading}
                  errorMsg={authUiState.login.error}
                />
                {/* <h3 style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "rgba(13, 30, 39, 0.5)",
                  margin:"20px 0"
                }}>
                  Log In With
                </h3>
                <div style={{ display: 'flex', justifyContent: 'center', margin: "0 0 20px 0" }}>
                  <a href="#"> <img src={require('../../view/Assets/new/facebook.png').default} alt="" /></a>
                  <a href="#"><img src={require('../../view/Assets/new/linkedin.png').default} alt="" /></a>
                  <a href="#"><img src={require('../../view/Assets/new/google.png').default} alt="" /></a>
                  <a href="#"><img src={require('../../view/Assets/new/twitter.png').default} alt="" /></a>
                </div> */}
                <div style={{ marginBottom: "auto" }}></div>
              </div>
            </div>
          </Container>
          {/* <GrassImage /> */}
        </div>
      </AnimatedPage>
    </>
  );
}
