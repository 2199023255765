import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Heading, Paragraph } from "../component/UI/styledItems";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
import ProductsCarousel from "../component/UI/ProductsCarousel";
import ParticlesBg from "particles-bg";

import mobileHandImage from "../Assets/products/mobilehand.png";
import mobileHandImage2 from "../Assets/products/mobilehand2.png";
import mobileHandImage3 from "../Assets/products/mobilehand3.png";
import mobileHandImage4 from "../Assets/products/mobilehand4.png";

const useStyles = makeStyles((theme) => ({
  productsDiv: {
    zIndex: 0,
    position: "relative",
    "& p": {
      textTransform: "none",
      lineHeight: 1.9,
    },
    [theme.breakpoints.down(670)]: {
      textAlign: "center",
    },
  },
  productImgDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down(950)]: {
      display: "block",
    },
  },
  textDiv: {
    width: "60%",
    [theme.breakpoints.down(950)]: {
      width: "100%",
    },
  },
  order: {
    order: 1,
    [theme.breakpoints.down(950)]: {
      order: "none",
    },
  },
}));

const Products = () => {
  const classes = useStyles();
  return (
    <>
      <MetaTags title={"Products"} />
      <AnimatedPage>
        <div className={classes.productsDiv} style={{ background: "#0D1E27" }}>
          <Container id="anti-theft" className={classes.productImgDiv}>
            <ParticlesBg type="cobweb" color="#80FFFB" bg={true} />
            <ProductsCarousel className={classes.order}>
              <img src={mobileHandImage} alt="" />
              <img src={mobileHandImage} alt="" />
              <img src={mobileHandImage} alt="" />
              <img src={mobileHandImage} alt="" />
              <img src={mobileHandImage} alt="" />
            </ProductsCarousel>
            <div className={classes.textDiv}>
              <Heading color="white" margin="0 0 2rem">
                Yoddha Anti-Theft Solution
              </Heading>
              <Paragraph margin="0" color="white">
                As the name says, it is anti-theft software. Our main goal is to
                protect the data from being misused. In today's world, it is
                hard for any of us to trust anybody, especially when it comes to
                our sensitive data. We never know what type of virus is there in
                which link and when it may enter our device. We even don't know
                that whether we are being guided safe or not. It is our
                responsibility to keep our data safe from an intruder. It is a
                great thing to become advance, but at the same time, we should
                even know how to manage our data so that it cannot be shared
                with anybody. We should be aware of the intruders and the empty
                blank links which lead us to false websites.
              </Paragraph>
              <Paragraph margin="1rem 0 0" color="white">
                Our Application even provides the facility in finding the lost
                phone. You can track your mobile. It is essential to note that
                when your phone is not with you, then where it is and in whose
                hands it is. Is it safe or not? When your phone is lost in our
                Application, it gets locked automatically and sends the SMS to
                the alternative number saved in the Application. It blocks
                everything so that no one can misuse your data. It will send the
                location details to the alternative number saved in the
                Application.
              </Paragraph>
            </div>
          </Container>
        </div>

        <div className={classes.productsDiv} style={{ background: "#DCF8D2" }}>
          <Container id="human-safety" className={classes.productImgDiv}>
            <ProductsCarousel>
              <img src={mobileHandImage2} alt="" />
              <img src={mobileHandImage2} alt="" />
              <img src={mobileHandImage2} alt="" />
              <img src={mobileHandImage2} alt="" />
              <img src={mobileHandImage2} alt="" />
            </ProductsCarousel>
            <div className={classes.textDiv}>
              <Heading color="#0D1E27" margin="0 0 2rem">
                Human Safety
              </Heading>
              <Paragraph margin="0" color="#0D1E27">
                Safety is the necessary point to which everybody should pay
                attention. In the present situation, being alone is dangerous,
                especially when it comes to females. Many times we travel alone,
                or mostly we are at home alone. Not every woman or girl needs to
                know judo and karate. It is a great thing to be prepared for
                everything and knows judo and karate, but what about those who
                don't know these martial arts. This is where our Application
                helps them. Women's safety is the hottest topic even in the
                parliament house. Every individual wants to keep their family
                safe. Keep them prepared for everything and face every problem
                boldly. Our Application is helpful in critical moments. An
                emergency SMS is sent to the selected numbers for help. In this
                way, we can keep our loved ones safe.
              </Paragraph>
              <Paragraph margin="1rem 0 0" color="#0D1E27">
                It's a bold step taken by many women or girls and children to
                learn self-defense and other martial arts. When they are going
                to school or private tuitions, we are always worried about their
                safety until they return home. They have learned to handle
                situations and when to react correctly so that they can be saved
                safely. As a parent, we must look after their safety. our
                Application can help you fulfill it. Men are always strong, but
                even they need help when they are in danger. Even their safety
                is essential. Our Application is also helpful for them.
              </Paragraph>
              <Paragraph margin="1rem 0 0" color="#0D1E27">
                We are pleased to know that we have contributed to keeping your
                family safe when they are alone. We are trying to make it easier
                for everyone to lead their lives safely without any fears of
                harassment or torture.
              </Paragraph>
            </div>
          </Container>
        </div>

        <div className={classes.productsDiv} style={{ background: "#0D1E27" }}>
          <Container id="retailer-app" className={classes.productImgDiv}>
            <ParticlesBg type="cobweb" color="#80FFFB" bg={true} />
            <ProductsCarousel className={classes.order}>
              <img src={mobileHandImage4} alt="" />
              <img src={mobileHandImage4} alt="" />
              <img src={mobileHandImage4} alt="" />
              <img src={mobileHandImage4} alt="" />
              <img src={mobileHandImage4} alt="" />
            </ProductsCarousel>
            <div className={classes.textDiv}>
              <Heading color="white" margin="0 0 2rem">
                Retailer App
              </Heading>
              <Paragraph margin="0" color="white">
                We want to allow those retailers and small businesses who want
                to invest and earn a good amount of profit out of it through our
                company. It is never too late to start investing. It is
                effortless for any of you to join us and start your business and
                grow with us. We provide different plans that help every
                individual, where you can invest in small amounts and gain high
                returns. We came up with innumerable options for retailers and
                small businesses where it is flexible for them to invest and
                earn returns. Every plan is unique in its way as it is designed
                so that everybody can invest in them and do business with us and
                contribute to the financial growth of India.
              </Paragraph>
              <Paragraph margin="1rem 0 0" color="white">
                We aim to help every individual through our company. We are
                providing a digital platform where you can keep you and your
                family secure through digital security.
              </Paragraph>
            </div>
          </Container>
        </div>
      </AnimatedPage>
    </>
  );
};

export default Products;
