import React from "react";
import { Button, makeStyles, Box } from "@material-ui/core";
import {
    Container,
    GrassImage,
    Heading,
    Paragraph,
} from "../component/UI/styledItems";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
const useStyles = makeStyles((theme) => ({
    careerBlob: {
        background: `url(/career/textBlob.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
    blobBottom: {
        position: "absolute",
        width: "11rem",
        bottom: "4rem",
        right: "0",
        zIndex: 1,
        display: "none",
        [theme.breakpoints.down(1000)]: {
            display: "block !important",
        },
        [theme.breakpoints.down(670)]: {
            bottom: "-8rem",
            right: "0",
            transform: "rotate(90deg)",
        },
    },
    heading: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "42px",
        lineHeight: "63px",
        letterSpacing: "0.04em",
        textTransform: "capitalize",
        color: "#0D1E27",
        textAlign: 'center'
    },
    card: {
        width: "274px",
        height: "241px",
        background: "#FFFFFF",
        boxShadow: "0px 8px 23px rgba(0, 0, 0, 0.07)",
        margin: "15px 0",
        padding: '10px'
    },
    title: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "25px",
        lineHeight: "37px",
        letterSpacing: "0.04em",
        textTransform: "capitalize",
        color: "#0D1E27"
    },
    para: {
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "17px",
        lineHeight: "25px",
        textTransform: "capitalize",
        color: "#0D1E27"
    },
    cardcontainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        [theme.breakpoints.down(670)]: {
            justifyContent: "center",
        },
    }
}));
const feautures = [
    // {
    //     image: require("../Assets/features/Subtract.png").default,
    //     title: "Live ",
    //     description: " test",
    // },
    {
        image: require("../Assets/features/Subtract.png").default,
        title: "Live Location",
        description: "Get a live location update of your mobile in case of emergency or mobile loss.",
    },
    {
        image: require("../Assets/features/simcard.png").default,
        title: "Sim card Lock ",
        description: " Lock your mobile whenever someone tries to remove your SIM",
    },
    {
        image: require("../Assets/features/women.png").default,
        title: "Women Safety ",
        description: " Photo link and location link notification will receive via SMS in case of emergency.",
    },
    {
        image: require("../Assets/features/child.png").default,
        title: "Child Activity ",
        description: " provides you with a detailed summary of the activities for your child.",
    },
    {
        image: require("../Assets/features/lock.png").default,
        title: "Mobile Protection ",
        description: " Disallow intruder to uninstall application from your mobile.",
    },
    {
        image: require("../Assets/features/usb.png").default,
        title: "USB Protection ",
        description: " provides you usb protection to secure your mobile’s personal data from theft.",
    },
    {
        image: require("../Assets/features/booster.png").default,
        title: "Gorilla Booster ",
        description: " Disallow intruder to uninstall application from your mobile.",
    },
    {
        image: require("../Assets/features/backup.png").default,
        title: "Data Backup",
        description: " Provide data backup options to secure your data in case of mobile theft.",
    },
    {
        image: require("../Assets/features/safety1.png").default,
        title: "App's Safety",
        description: " Disallow intruder to uninstall application from your mobile.",
    },
    {
        image: require("../Assets/features/exit.png").default,
        title: "Don't Touch Phone",
        description: "Disallow intruder to uninstall application from your mobile.",
    },
    {
        image: require("../Assets/features/photo.png").default,
        title: "Photo Capture",
        description: "Lock your mobile whenever someone tries to remove your SIM.",
    },
    {
        image: require("../Assets/features/wallet.png").default,
        title: "Pocket Lock",
        description: "Lock your mobile whenever someone tries to remove your SIM.",
    },
    {
        image: require("../Assets/features/safety.png").default,
        title: "Movement Lock",
        description: "Photo link and location link notification will receive via SMS in case of emergency.",
    },
    {
        image: require("../Assets/features/mobile.png").default,
        title: "Screen Lock",
        description: "Photo link and location link notification will receive via SMS in case of emergency.",
    },
    {
        image: require("../Assets/features/mute.png").default,
        title: "Slient Lock  ",
        description: "Get a live location update of your mobile in case of emergency or mobile loss.",
    },
    {
        image: require("../Assets/features/remote.png").default,
        title: "Remote Lock",
        description: "Lock your mobile whenever someone tries to remove your SIM. ",
    },
    {
        image: require("../Assets/features/watch.png").default,
        title: "Wrong Lock Alarm",
        description: "Photo link and location link notification will receive via SMS in case of emergency.",
    },
    {
        image: require("../Assets/features/cross.png").default,
        title: "Don't Switch Off ",
        description: "Photo link and location link notification will receive via SMS in case of emergency.",
    },
];
const Features = () => {
    const classes = useStyles();
    return (
        <>
            <MetaTags title={"Career"} />
            <AnimatedPage>
                <Container maxWidth="xl">
                    <h2 className={classes.heading}>Unlimited Features</h2>
                    <br />
                    <br />
                    <Box className={classes.cardcontainer} >
                        {feautures.map((data, i) => (
                            <div key={i}>
                                <Box className={classes.card}>
                                    <img
                                        src={data.image}
                                        alt="Team Member"
                                    />
                                    <h3 className={classes.title}>{data.title}</h3>
                                    <p className={classes.para}>{data.description}
                                    </p>
                                </Box>
                            </div>

                        ))}
                    </Box>
                </Container>
            </AnimatedPage>
        </>
    );
};
export default Features
