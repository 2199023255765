import React from "react";
import { motion } from "framer-motion";

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: {
    opacity: 0,
    delay: 0.2,
  },
  enter: {
    opacity: 1,
    transition: { ...transition },
  },
  exit: {
    opacity: 0,
    transition: { ...transition, duration: 0.2 },
  },
};

const AnimatedPage = ({ children }) => {
  return (
    <motion.div
      variants={thumbnailVariants}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;
