import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import { Input } from "./UI";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "75%",
    maxWidth: "25rem",
    padding: "0 1rem",
  },

  textField: {
    width: "100%",
    "& + &": {
      marginTop: "1.1rem",
    },
  },

  button: {
    fontSize: "1.3rem",
    lineHeight: 2.1,
    padding: "0.2rem 2.5rem",
    background: "#F0F0F8",
    border: "none",
    outline: "none",
    color: "#0D1E27",
    cursor: "pointer",
    boxShadow:
      "inset -2px -2px 4px #FFFFFF, inset 2px 2px 4px rgba(0, 0, 0, 0.25), -3px -3px 6px #FFFFFF, 3px 3px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    fontWeight: 600,
    fontFamily: "inherit",
    "& > span": {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      boxShadow: "-3px -3px 6px #FFFFFF, 3px 3px 6px rgba(0, 0, 0, 0.25)",
      zIndex: 2,
      background: "#F0F0F8",
      borderRadius: "5px",
      transition: "opacity 0.5s",
      opacity: 0,
      paddingTop: "3px",
      "&:hover": {
        opacity: 1,
      },
    },
  },

  textFieldDiv: {
    maxHeight: "100%",
    overflow: "auto",
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      overflow: "visible",
    },
  },

  loadingButtonDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "2.8rem",
    width: "3.5rem",
    "& svg": {
      color: "#0D1E27",
    },
  },
}));

const FormikForm = ({
  label,
  form,
  initialValues,
  schema,
  onSubmit,
  loading,
  errorMsg,
}) => {
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize //very imp thing here
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
      }) => {
        return (
          <form className={classes.form} style={{ width: "100%" }}>
            {form.map((fieldItem, i) => (
              <Input
                key={i}
                className={classes.textField}
                label={fieldItem.label}
                name={fieldItem.name}
                type={fieldItem.type}
                value={values[fieldItem.name] || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors[fieldItem.name] && touched[fieldItem.name]}
                helperText={
                  touched[fieldItem.name] ? errors[fieldItem.name] : ""
                }
              />
            ))}
            {errorMsg && (
              <p
                style={{
                  color: "red",
                  fontSize: "0.85rem",
                  maxWidth: "25rem",
                  textAlign: "center",
                }}
              >
                {errorMsg}
              </p>
            )}

            <Box display="flex" justifyContent="center" mt="2.5rem">
              <Box position="relative">
                {loading ? (
                  <button className={classes.button}>
                    <div className={classes.loadingButtonDiv}>
                      <CircularProgress size={30} thickness={5} />
                    </div>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={classes.button}
                    onClick={handleSubmit}
                  >
                    {label}
                    <span>{label}</span>
                  </button>
                )}
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormikForm;
