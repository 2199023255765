import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import MetaTags from "../../component/MetaTags";
import { AnimatedPage } from "../../component/UI";
import {
  Container,
  StyledTableCell,
  StyledTableRow,
} from "../../component/UI/styledItems";
import SearchIcon from "@material-ui/icons/Search";
import AdminTables from "../../component/UI/AdminTables";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import dateFormat from ".././..//utils/dateFormat";
import { useDispatch } from "react-redux";
import {
  getActivationCodes,
  getTokensAggregations,
  disableToken,
} from "../../../application/reducers/adminSlice";
import { PAYMENT_TYPE } from "./utils/contants";

const useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    fontSize: "2rem",
    fontWeight: 600,
    color: "#35799D",
    textTransform: "uppercase",
  },
  activationCode: {
    borderBottom: "1px solid #0D1E27",
    fontWeight: 500,
    fontSize: "1.5rem",
    display: "inline-block",
    color: "#0D1E27",
  },
  filterSearchDiv: {
    display: "flex",
    alignItems: "center",
    margin: "1rem 0",
  },
  filterText: {
    fontSize: "1rem",
    fontWeight: 600,
    margin: 0,
    marginRight: "0.7rem",
  },
  filterSelect: {
    minWidth: "10rem",
    paddingLeft: "0.5rem",
  },
  filterInputDiv: {
    border: "none",
    background: "white",
    borderRadius: 7,
    paddingLeft: "0.6rem",
    overflow: "hidden",
    width: "15rem",
    "& svg": {
      color: "#6A6A6A",
    },
    "& input": {
      marginLeft: 10,
      height: "2.5rem",
      fontFamily: "inherit",
      border: "none",
      outline: "none",
      width: "100%",
      color: "#6A6A6A",
      fontWeight: 500,
      "&::-webkit-input-placeholder": {
        color: "gray",
        fontWeight: 400,
      },
      "&:-ms-input-placeholder": {
        color: "gray",
        fontWeight: 400,
      },
      "&::placeholder": {
        color: "gray",
        fontWeight: 400,
      },
    },
  },
  tableCont: {
    padding: "0.8rem",
    paddingBottom: 0,
    marginTop: "1.5rem",
    background: "white",
    boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.2)",
  },
  tableInfoDiv: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      marginRight: "auto",
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#0D1E27",
    },
  },
  tableControllerButtons: {
    padding: 0,
    "& svg": {
      fontSize: "2rem",
      color: "#0D1E27",
    },
  },
  errorP: {
    color: "red",
    fontSize: "0.9rem",
    textAlign: "center",
  },
  loadingDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem 0",
  },
}));

const headData = [
  "#",
  "Activation Code",
  "Name",
  "Number",
  "Generation Date",
  "Type",
  "Disabled",
  "Status",
];

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isTableEmpty, setIsTableEmpty] = useState(false);

  const [filter, _setFilter] = useState("all");
  const setFilter = (e) => {
    if (filter !== e.target.value) {
      _setFilter(e.target.value);
      setTableBody([]);
      setTokenData([]);
      tokenDataCount.current = 0;

      fetchData({ startAfter: null, filter: e.target.value, duration })
        .then((tokens) => {
          if (tokens.length === 0) setIsTableEmpty(true);
          setTableBody(tokens.slice(0, docsToDisplay));
        })
        .catch((err) => console.log(err));
    }
  };

  const [duration, _setDuration] = useState("all");
  const setDuration = (e) => {
    if (duration !== e.target.value) {
      _setDuration(e.target.value);
      setTableBody([]);
      setTokenData([]);
      tokenDataCount.current = 0;

      fetchData({ startAfter: null, filter, duration: e.target.value })
        .then((tokens) => {
          if (tokens.length === 0) setIsTableEmpty(true);
          setTableBody(tokens.slice(0, docsToDisplay));
        })
        .catch((err) => console.log(err));
    }
  };

  const searchingWithPhone = useRef(false);
  const [phoneNumber, _setPhoneNumber] = useState("");
  const setPhoneNumber = (e) => {
    const value = e.target.value;
    if (value.length > 10) return;
    _setPhoneNumber(value);

    if (value.length === 10 && Number(value)) {
      searchingWithPhone.current = true;

      setTableBody([]);
      setTokenData([]);
      tokenDataCount.current = 0;

      fetchData({ startAfter: null, filter, phone: value, duration })
        .then((tokens) => {
          if (tokens.length === 0) setIsTableEmpty(true);
          setTableBody(tokens.slice(0, docsToDisplay));
        })
        .catch((err) => console.log(err));
    } else if (searchingWithPhone.current) {
      searchingWithPhone.current = false;

      setTableBody([]);
      setTokenData([]);
      tokenDataCount.current = 0;

      fetchData({ startAfter: null, filter, duration })
        .then((tokens) => {
          if (tokens.length === 0) setIsTableEmpty(true);
          setTableBody(tokens.slice(0, docsToDisplay));
        })
        .catch((err) => console.log(err));
    }
  };

  const [tokenAggrData, setTokenAggrData] = useState({});
  const [tokenData, setTokenData] = useState([]);
  const [error, setError] = useState("");

  const [tableBody, setTableBody] = useState([]);

  const tokenDataCount = useRef(0);
  const limit = 60;
  const docsToDisplay = 10;
  useEffect(() => {
    fetchData({ startAfter: null, filter, duration })
      .then((tokens) => {
        if (tokens.length === 0) setIsTableEmpty(true);
        setTableBody(tokens.slice(0, docsToDisplay));
      })
      .catch((err) => console.log(err));

    dispatch(getTokensAggregations())
      .then(({ payload }) => setTokenAggrData(payload.data))
      .catch((err) => console.log(err.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const fetchData = async ({ startAfter, filter = "all", phone, duration }) => {
    if (isTableEmpty) setIsTableEmpty(false);
    try {
      if (error.length !== 0) setError("");

      let { payload } = await dispatch(
        getActivationCodes({
          startAfter,
          limit,
          filter,
          phone,
          duration,
        })
      );

      if (!payload) return;

      let tokens = payload.tokens;

      let i = tokenDataCount.current;
      tokens = tokens.map((token) => {
        return {
          ...token,
          i: ++i,
        };
      });

      tokenDataCount.current = tokenDataCount.current + tokens.length;
      setTokenData((tokenData) => tokenData.concat(tokens));
      return tokens;
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    }
  };

  const showPrevTableRecords = () => {
    const currentFirstIndex = tableBody[0].i;

    if (currentFirstIndex === 1) return; // Reached beginning of table

    // console.log("end data");

    setTableBody(
      tokenData.slice(
        currentFirstIndex - docsToDisplay - 1,
        currentFirstIndex - 1
      )
    );
  };

  const showNextTableRecords = () => {
    const currentLastIndex = tableBody[tableBody.length - 1].i;
    const tokenDataLastIndex = tokenData[tokenData.length - 1].i;
    const recordsLeftBeforeLoadMoreData = 20;

    if (currentLastIndex === tokenData.length - recordsLeftBeforeLoadMoreData) {
      // time to load more data... will load data only once when we reach tokenData.length - recordsLeftBeforeLoadMoreData despite of how many time we click next
      if (tokenData.length !== tokenAggrData.total) {
        // there is more data left to load...
        const lastItemTokenId = tokenData[tokenData.length - 1].tokenId;
        // Sending network req to load more data...
        fetchData({ startAfter: lastItemTokenId, filter, duration });
        console.log("load more data");
      }
    }

    if (currentLastIndex === tokenDataLastIndex) return; // reached end of data
    setTableBody(
      tokenData.slice(currentLastIndex, currentLastIndex + docsToDisplay)
    );
  };

  const toggleTokenDisabled =
    ({ disabled, globalIndex, index, tokenId }) =>
    () => {
      dispatch(disableToken({ action: !disabled, tokenId })).then((res) => {
        if (res.payload) {
          const newTokenData = JSON.parse(JSON.stringify(tokenData));
          newTokenData[globalIndex].disabled = !disabled;
          const newTableBody = JSON.parse(JSON.stringify(tableBody));
          newTableBody[index].disabled = !disabled;

          setTableBody(newTableBody);
          setTokenData(newTokenData);
        }
      });
    };

  return (
    <>
      <MetaTags title={"Admin Dashboard"} />
      <AnimatedPage>
        <div style={{ background: "#F0F0F8" }}>
          <Container>
            <h1 className={classes.heading}>Dashboard</h1>
            <h2 className={classes.activationCode}>Activation Code</h2>
            <div className={classes.filterSearchDiv}>
              <Box display="flex" alignItems="center">
                <h4 className={classes.filterText}>Filter:</h4>
                <Select
                  value={filter}
                  onChange={setFilter}
                  classes={{
                    select: classes.filterSelect,
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="activated">Activated</MenuItem>
                  <MenuItem value="deactivated">Deactivated</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                </Select>
              </Box>
              <Box display="flex" alignItems="center" m="auto">
                <h4 className={classes.filterText}>Duration:</h4>
                <Select
                  value={duration}
                  onChange={setDuration}
                  classes={{
                    select: classes.filterSelect,
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value={PAYMENT_TYPE.yearly}>Yearly</MenuItem>
                  <MenuItem value={PAYMENT_TYPE.five_yearly}>
                    Five-Yearly
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.filterInputDiv}
              >
                <SearchIcon />
                <input
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  type="text"
                  placeholder="Search Phone Number"
                />
              </Box>
            </div>

            <div className={classes.tableCont}>
              <AdminTables headData={headData}>
                {tableBody.map((row, index) => (
                  <StyledTableRow key={row.tokenId}>
                    <StyledTableCell>{row.i}</StyledTableCell>
                    <StyledTableCell>{row.tokenId}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                    <StyledTableCell>
                      {row.registeredAt && dateFormat(row.registeredAt)}
                    </StyledTableCell>
                    <StyledTableCell>{row.type}</StyledTableCell>
                    <StyledTableCell>
                      <span
                        onClick={toggleTokenDisabled({
                          disabled: row.disabled,
                          globalIndex: row.i - 1,
                          index: index,
                          tokenId: row.tokenId,
                        })}
                        style={{
                          cursor: "pointer",
                          padding: "0.25rem",
                          display: "block",
                          textAlign: "center",
                          background: "#585858",
                          alignSelf: "center",
                          borderRadius: "0.2rem",
                          color: "white",
                        }}
                      >
                        {row.disabled ? "Yes" : "No"}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.registered ? (
                        <span style={{ color: "green" }}>Active</span>
                      ) : (
                        <span style={{ color: "#e80707" }}>Inactive</span>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </AdminTables>
              {isTableEmpty ? (
                <p className={classes.errorP} style={{ color: "black" }}>
                  No data to show here.
                </p>
              ) : (
                tokenData.length === 0 &&
                !error && (
                  <div className={classes.loadingDiv}>
                    <CircularProgress
                      thickness={6}
                      size={30}
                      style={{ color: "gray" }}
                    />
                  </div>
                )
              )}
              {error.length !== 0 && (
                <p className={classes.errorP}>Failed to load data...</p>
              )}
              <div className={classes.tableInfoDiv}>
                <p>
                  Showing {tableBody[0]?.i}-{tableBody[tableBody.length - 1]?.i}{" "}
                  of{" "}
                  {filter === "all"
                    ? tokenAggrData.total
                    : filter === "activated"
                    ? tokenAggrData.registered
                    : tokenAggrData.total - tokenAggrData.registered}{" "}
                  Activation Codes
                </p>
                <IconButton
                  className={classes.tableControllerButtons}
                  onClick={showPrevTableRecords}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  className={classes.tableControllerButtons}
                  style={{ marginLeft: -3, marginRight: 5 }}
                  onClick={showNextTableRecords}
                >
                  <ChevronRightIcon />
                </IconButton>
              </div>
            </div>
          </Container>
        </div>
      </AnimatedPage>
    </>
  );
};

export default Dashboard;
