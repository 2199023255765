import React, { useState, useRef, useMemo } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { AnimatedPage, Carousel } from "../component/UI";
import {
  Heading,
  Paragraph,
  Button,
  TeamsPaper,
  SmallHeading,
  SmallParagraph,
  Container,
  GrassImage,
} from "../component/UI/styledItems";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Slider from "react-slick";

import ParticlesBg from "particles-bg";
import MetaTags from "../component/MetaTags";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  blobTop: {
    position: "absolute",
    width: "25rem",
    top: "-5rem",
    left: "0rem",
  },
  blobText: {
    color: "#A2D196",
    fontWeight: 800,
    fontSize: "6.5rem",
    position: "absolute",
    top: "-5rem",
    left: "10rem",
    zIndex: 100,
    [theme.breakpoints.down(800)]: {
      fontSize: "6rem",
      top: "-4.5rem",
    },
    [theme.breakpoints.down(670)]: {
      fontSize: "5rem",
      top: "-3rem",
      left: "calc( 5rem + 10vw )",
    },
  },
  carItems: {
    minHeight: "42rem",
    height: "calc( 100vh - 8rem )",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(670)]: {
    justifyContent: "center",
    },
  },
  textDiv: {
    width: "30rem",
    position: "relative",
    zIndex: 10,
    [theme.breakpoints.down(1000)]: {
      width: "37rem",
    },
    [theme.breakpoints.down(670)]: {
      textAlign: "center",
    },
  },
  marginTop: {
    marginTop: "3.5rem",
    [theme.breakpoints.down(670)]: {
      marginTop: "-150px",
    },
  },
  heading: {
    margin: "0 0 1.5rem",
    textDecoration: "none",
  },
  rightImage: {
    width: "23rem",
    margin: "auto",
    position: "relative",
    zIndex: 5,
    [theme.breakpoints.down(1000)]: {
      display: "none !important",
    },
  },
  HomeRightImage: {
    // width: "23rem",
    // margin: "auto",
    // position: "relative",
    // zIndex: 5,
    [theme.breakpoints.down(1000)]: {
      display: "none !important",
    },
  },
  blobBottom: {
    position: "absolute",
    width: "14rem",
    bottom: "4rem",
    right: "0",
    zIndex: 1,
    [theme.breakpoints.down(670)]: {
      width: "14rem",
      bottom: "-8rem",
      right: "0",
      transform: "rotate(90deg)",
    },
  },
  aboutUs: {
    marginTop: "-4px",
    background: "#012147",
    position: "relative",
  },
  aboutUsContainer: {
    display: "flex",
    alignItems: "center",
  },
  ourProductsGrid: {
    display: "grid",
    gridTemplateColumns: "3.5fr 3fr 5.5fr",
    columnGap: "5rem",
    marginTop: "3rem",
    [theme.breakpoints.down(1200)]: {
      gridTemplateColumns: "4fr 8fr",
    },
    [theme.breakpoints.down(1000)]: {
      display: "block",
    },
  },
  productItem: {
    lineHeight: 3.1,
    width: "14.5rem",
    textAlign: "center",
    borderRadius: 0,
    fontWeight: 600,
    fontSize: "1.5rem",
    margin: "1.2rem 0",
    position: "relative",
    color: "#272727",
    transition: "0.5s",
    cursor: "pointer",
    "&::after": {
      content: "''",
      width: 0,
      height: 0,
      borderTop: "11px solid transparent",
      borderBottom: "11px solid transparent",
      borderLeft: "18px solid #1C232D",
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(100%, -50%)",
      opacity: 0,
      transition: "0.5s",
      transitionTimingFunction: "ease-out",
    },
    [theme.breakpoints.down(1455)]: {
      width: "13rem",
      fontSize: "1.2rem",
    },
  },
  productItemSelected: {
    marginLeft: "2rem",
    background: "#1C232D",
    color: "white",
    "&::after": {
      opacity: 100,
    },
  },
  productImage: {
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
  },
  productTextDiv: {
    display: "flex",
    flexDirection: "column",
    color: "#272727",
    padding: "1rem 0",
    [theme.breakpoints.down(1000)]: {
      "& h4": {
        color: "#515151",
        marginBottom: "0.7rem",
      },
      "& p": {
        color: "#272727",
      },
    },
  },
  arrowDiv: {
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-start",
    marginTop: 0,
    "& svg": {
      fontSize: "1rem",
      color: "#272727",
    },
  },
  ourTeam: {
    background: "#F0F0F8",
    paddingBottom: "5rem",
    position: "relative",
  },
  teamsButton: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      transform: "scale( 1.05 )",
    },
    "& svg": {
      fontSize: "1rem",
      margin: "auto",
      [theme.breakpoints.down(400)]: {
        fontSize: "0.85rem",
      },
    },
    [theme.breakpoints.down(400)]: {
      width: "2rem",
      height: "2rem",
    },
  },
  teamItem: {
    maxWidth: "90%",
    display: "flex",
    flexDirection: "column",
    // border:'1px solid red',
    // width:"300px"
  },
  memberImage: {
    width: "7rem",
    borderRadius: "100%",
    margin: "0 auto 1.5rem",
    overflow: "hidden",
  },
  mediaHandles: {
    margin: "0 auto",
    transition: "transform 0.3s",
    "& > div": {
      overflow: "hidden",
      [theme.breakpoints.down(600)]: {
        width: "2.5rem",
        height: "2.5rem",
      },
      [theme.breakpoints.down(400)]: {
        width: "2rem",
        height: "2rem",
      },
      "& > img": {
        color: "#1C232D",
        margin: "auto",
        height: "40%",
      },
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "42px",
    lineHeight: "63px",
    letterSpacing: "0.04em",
    textTransform: "capitalize",
    color: "#0D1E27",
    textAlign: 'center'
  },
  card: {
    width: "274px",
    height: "241px",
    background: "#FFFFFF",
    boxShadow: "0px 8px 23px rgba(0, 0, 0, 0.07)",
    margin: "15px 0",
    padding: '10px'
  },
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "25px",
    lineHeight: "37px",
    letterSpacing: "0.04em",
    textTransform: "capitalize",
    color: "#0D1E27"
  },
  para: {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "17px",
    lineHeight: "25px",
    textTransform: "capitalize",
    color: "#0D1E27"
  },
  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down(670)]: {
      justifyContent: "center",
    },
  },
  features:{
    display:'flex',
    alignItems:'center',
    [theme.breakpoints.down(670)]: {
     flexWrap:'wrap',
     justifyContent:'center'
    },
  },
  featuresimg:{
    [theme.breakpoints.down(670)]: {
      display:'none'
    },
  },
  dbutton:{
    // border:'1px solid red',
    [theme.breakpoints.down(670)]: {
      // display:'none'
      margin:'auto 50px'
    },
  }
}));

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  speed: 500,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const carouselData = [
  {
    // slideNum: "01",
    heading: "Anti-Theft",
    description:
      "As the name says, it is anti-theft software. Our main goal is to protect the data from being misused.",
  },
  {
    // slideNum: "02",
    heading: "Human Safety",
    description:
      "Safety is the necessary point to which everybody should pay attention. In the present situation, being alone is dangerous.",
  },
  {
    // slideNum: "03",
    heading: "Retailer App",
    description:
      "We want to allow those retailers and small businesses who want to invest and earn a good amount of profit out of it through our company.",
  },
];

const teamMembers = [
  {
    image: require("../Assets/teamMembers/golu.jpg").default,
    name: "Golu Vishwakarma",
    position: "Director",
  },
  {
    image: require("../Assets/teamMembers/chetan.jpg").default,
    name: "Chetan Sharma",
    position: "Director",
  },
  {
    image: require("../Assets/teamMembers/apoorv.jpg").default,
    name: "Apoorv Singh Chouhan",
    position: "Director",
  },
  {
    image: require("../Assets/teamMembers/mohan.jpg").default,
    name: "Mohan Tanwar",
    position: "Director",
  },
  {
    image: require("../Assets/teamMembers/ashutosh.jpg").default,
    name: "Ashutosh Singh Rajput",
    position: "Director",
  },
];
const feautures = [
  // {
  //     image: require("../Assets/features/Subtract.png").default,
  //     title: "Live ",
  //     description: " test",
  // },
  {
    image: require("../Assets/features/Subtract.png").default,
    title: "Live Location",
    description: "Get a live location update of your mobile in case of emergency or mobile loss.",
  },
  {
    image: require("../Assets/features/simcard.png").default,
    title: "Sim card Lock ",
    description: " Lock your mobile whenever someone tries to remove your SIM",
  },
  {
    image: require("../Assets/features/women.png").default,
    title: "Women Safety ",
    description: " Photo link and location link notification will receive via SMS in case of emergency.",
  },
  {
    image: require("../Assets/features/child.png").default,
    title: "Child Activity ",
    description: " provides you with a detailed summary of the activities for your child.",
  },
  {
    image: require("../Assets/features/lock.png").default,
    title: "Mobile Protection ",
    description: " Disallow intruder to uninstall application from your mobile.",
  },
  {
    image: require("../Assets/features/usb.png").default,
    title: "USB Protection ",
    description: " provides you usb protection to secure your mobile’s personal data from theft.",
  },
];
const Home = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:1000px)");

  const [productItem, setProductItem] = useState(0);

  const productItemsArr = useMemo(
    () => [
      {
        title: "Anti-Theft",
        description:
          "As the name says, it is anti-theft software. Our main goal is to protect the data from being misused. In today's world, it is hard for any of us to trust anybody, especially when it comes to our sensitive data.",
        selected: productItem === 0,
        link: "/products#anti-theft",
        onClick: () => {
          if (productItem !== 0) setProductItem(0);
        },
      },
      {
        title: "Human Safety",
        description:
          "Safety is the necessary point to which everybody should pay attention. In the present situation, being alone is dangerous, especially when it comes to females.",
        selected: productItem === 1,
        link: "/products#human-safety",
        onClick: () => {
          if (productItem !== 1) setProductItem(1);
        },
      },
      {
        title: "Retailer App",
        description:
          "We want to allow those retailers and small businesses who want to invest and earn a good amount of profit out of it through our company. It is never too late to start investing.",
        selected: productItem === 2,
        link: "products#retailer-app",
        onClick: () => {
          if (productItem !== 2) setProductItem(2);
        },
      },
    ],
    [productItem]
  );

  const sliderRef = useRef();

  function next() {
    if (sliderRef.current) sliderRef.current.slickNext();
  }
  function previous() {
    if (sliderRef.current) sliderRef.current.slickPrev();
  }

  return (
    <>
      <MetaTags title={"Home"} />
      <AnimatedPage>
        <Carousel>
          {carouselData.map(({ slideNum, heading, description }, i) => (
            <div key={i}>
              {/* <img
                className={classes.blobTop}
                src={require("../Assets/home/blobTop.svg").default}
                alt=""
              /> */}
              <p className={classes.blobText}>{slideNum}</p>
              <Container
                className={classes.carItems}
                style={{ padding: "3.5rem" }}
              >
                <div className={`${classes.textDiv} ${classes.marginTop}`}>
                  <Heading className={classes.heading}>{heading}</Heading>
                  <Paragraph color="#1C232D" margin="0 0 2rem">
                    {description}
                  </Paragraph>
                  <div className={classes.dbutton}>
                  <a href="https://drive.google.com/file/d/1VWnT-VW0WDy5WjCYcFFPcM_RykrhQB7x/view?usp=sharing" target="_blank">
                    <img src={require("../Assets/new/download.png").default} alt="" />
                  </a>
                  </div>
                  {/* <Button color="#1C232D" hoverColor="#DCF8D2">
                    Download
                  </Button> */}
                </div>
                <div className={classes.HomeRightImage}>
                  <img
                    // style={{ width: "100%" }}
                    src={require("../Assets/homemobile.png").default}
                    alt="mobile displaying anti theft location"
                  />
                </div>
              </Container>
              {/* <img
                className={classes.blobBottom}
                src={require("../Assets/blobBottom.svg").default}
                alt=""
              /> */}
            </div>
          ))}
        </Carousel>
        <div className={classes.aboutUs}>
          <div style={{ zIndex: 5 }}>
            <ParticlesBg type="cobweb" color="#80FFFB" bg={true} />
          </div>
          <Container className={classes.aboutUsContainer}>
            <div className={classes.textDiv}>
              <Heading color="white" margin="0 0 1.5rem">
                About Us
              </Heading>
              <Paragraph color="#E2E2E2" margin="0 0 2rem">
                More and High Technology Private Limited, which Yoddha produces.
                Our main goal is to protect every human being and the data being
                used by them. In today's world, it is tough to keep our data
                safe.
              </Paragraph>
              <NavLink to="/about-us">
                <Button color="white" hoverColor="#012147">
                  Read More
                </Button>
              </NavLink>
            </div>
            <div className={classes.rightImage}>
              <img
                style={{ width: "100%" }}
                src={require("../Assets/newabout.png").default}
                alt="mobile displaying anti theft location"
              />
            </div>
          </Container>
        </div>

        <Container>
          <Heading color="#1C232D" margin="0">
            Our Products
          </Heading>
          <Box className={classes.ourProductsGrid}>
            <Box
              display={{ 0: "none", 1000: "flex" }}
              flexDirection="column"
              justifyContent="center"
            >
              {productItemsArr.map((item, i) => (
                <Paper
                  key={i}
                  variant={item.selected ? "outlined" : "elevation"}
                  elevation={item.selected ? 0 : 5}
                  className={
                    item.selected
                      ? `${classes.productItem} ${classes.productItemSelected}`
                      : classes.productItem
                  }
                  onClick={item.onClick}
                >
                  {item.title}
                </Paper>
              ))}
            </Box>
            <Box display="flex" className={classes.productImage}>
              <div style={{ margin: "auto auto auto 0", width: "90%" }}>
                <img
                  style={{ width: "100%" }}
                  src={require("../Assets/newmobile.png").default}
                  alt=""
                />
              </div>
            </Box>
            {isMobile ? (
              productItemsArr.map((item, i) => (
                <Box
                  key={i}
                  className={classes.productTextDiv}
                  marginTop="1.5rem"
                >
                  <SmallHeading margin="auto 0 1.2rem">
                    {item.title}
                  </SmallHeading>
                  <SmallParagraph margin="0 0 1.2rem">
                    {item.description}
                  </SmallParagraph>

                  <NavLink className={classes.arrowDiv} to={item.link}>
                    <SmallParagraph style={{ margin: 0 }}>
                      Read More
                    </SmallParagraph>
                    <ArrowForwardIosIcon
                      style={{ transform: "translateX( 0.6rem )" }}
                    />
                    <ArrowForwardIosIcon />
                  </NavLink>
                </Box>
              ))
            ) : (
              <Box className={classes.productTextDiv}>
                <SmallHeading margin="auto 0 1.2rem">
                  {productItemsArr[productItem].title}
                </SmallHeading>
                <SmallParagraph margin="0 0 1.2rem">
                  {productItemsArr[productItem].description}
                </SmallParagraph>
                <NavLink
                  className={classes.arrowDiv}
                  to={productItemsArr[productItem].link}
                >
                  <SmallParagraph style={{ margin: 0 }}>
                    Read More
                  </SmallParagraph>
                  <ArrowForwardIosIcon
                    style={{ transform: "translateX( 0.6rem )" }}
                  />
                  <ArrowForwardIosIcon />
                </NavLink>
              </Box>
            )}
          </Box>
        </Container>
        {/* Features */}
        <Container maxWidth="xl">
          <h2 className={classes.heading}>Unlimited Features</h2>
          <br />
          <br />
          <Box className={classes.features} >
            <div>
              <img src={require('../Assets/new/homefeature.png').default} alt="" className={classes.featuresimg}  />
            </div>
            <Box className={classes.cardcontainer} >
              {feautures.map((data, i) => (
                <div key={i}>
                  <Box className={classes.card}>
                    <img
                      src={data.image}
                      alt="Team Member"
                    />
                    <h3 className={classes.title}>{data.title}</h3>
                    <p className={classes.para}>{data.description}
                    </p>
                  </Box>
                </div>
              ))}
              <NavLink to="/features">
                <br />
                <a href=""
                  style={{ color: '#58B63A', fontSize: '18px', padding: '6px 33px', border: '1px solid #58B63A', borderRadius: '5px' }}>View More</a>
              </NavLink>

            </Box>
          </Box>
        </Container>
        <div className={classes.ourTeam}>
          <Container>
            <Box display="flex" alignItems="center" marginBottom="4.5rem">
              <Heading color="#1C232D" margin="0 auto 0 0">
                Our Team
              </Heading>
              <TeamsPaper
                size="small"
                dimension="2.5rem"
                margin="0.5rem"
                className={classes.teamsButton}
                onClick={previous}
              >
                <ArrowForwardIosIcon
                  htmlColor="#1C232D"
                  style={{
                    transform: "rotate( 180deg )",
                  }}
                />
              </TeamsPaper>
              <TeamsPaper
                size="small"
                dimension="2.5rem"
                margin="0.5rem"
                className={classes.teamsButton}
                onClick={next}
              >
                <ArrowForwardIosIcon htmlColor="#1C232D" />
              </TeamsPaper>
            </Box>
            <Slider
              ref={sliderRef}
              {...settings}
              style={{ margin: "0 -1.4rem" }}
            >
              {teamMembers.map((member, i) => (
                <div key={i}>
                  <TeamsPaper
                    className={classes.teamItem}
                    padding="2rem"
                    margin="0.5rem auto"
                  >
                    <div className={classes.memberImage}>
                      <img
                        style={{ width: "100%" }}
                        // src={member.image}
                        src={require("../Assets/teamMembers/dummy.png").default}
                        alt="Team Member"
                      />
                    </div>
                    <SmallHeading color="#1C232D" margin="0 0 0.3rem">
                      {/* {member.name} */}
                      {"Member"}
                    </SmallHeading>
                    <SmallHeading color="#455A64" margin="0">
                      {member.position}
                    </SmallHeading>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <a href="#"> <img src={require('../../view/Assets/new/facebook.png').default} alt="" /></a>
                      <a href="#"><img src={require('../../view/Assets/new/linkedin.png').default} alt="" /></a>
                      <a href="#"><img src={require('../../view/Assets/new/google.png').default} alt="" /></a>
                      <a href="#"><img src={require('../../view/Assets/new/twitter.png').default} alt="" /></a>
                    </div>
                    {/* <Box display="flex" marginTop="auto" paddingTop="0.7rem">
                      {member.mediaHandles.map((handle, i) => (
                        <a
                          href={handle.link}
                          key={i}
                          className={classes.mediaHandles}
                        >
                          <TeamsPaper
                            size="small"
                            borderRadius="100%"
                            dimension="3rem"
                          >
                            <img style={{ width: "100%" }} src={handle.image} alt={handle.alt} />
                          </TeamsPaper>
                        </a>
                      ))}
                    </Box> */}
                  </TeamsPaper>
                </div>
              ))}
            </Slider>
            {/* <GrassImage /> */}
          </Container>
        </div>
      </AnimatedPage>
    </>
  );
};

export default Home;
