import { Box } from "@material-ui/core";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../component/UI";
import {
  getPaymentOrder,
  paymentSelectors,
  verifyPayment,
} from "../../application/reducers/paymentSlice";
import MetaTags from "../component/MetaTags";
import logo from "../Assets/moreLogo.png";
import { Formik } from "formik";
import * as yup from "yup";
import { PAYMENT_TYPE } from "./admin/utils/contants";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70vh",
    position: "relative",
    display: "flex",
  },
  loader: {
    position: "absolute",
    inset: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paymentDiv: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
    flex: 1,
    maxWidth: 500,
    padding: "1rem",
  },
  sendButton: {
    background: "#F0F0F8",
    boxShadow: "-3px -3px 6px #FFFFFF, 3px 3px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    padding: "0.5rem 3rem",
    fontWeight: 500,
    fontSize: "1rem",
    fontFamily: "inherit",
    textTransform: "none",
    color: "#1C232D",
    maxWidth: 250,
    margin: "auto",
  },
}));

const useScript = (url) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    script.onload = () => {
      setLoaded(true);
    };
    script.onerror = () => {
      setLoaded(false);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return loaded;
};

export default function Payment() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [paymentType, setPaymentType] = useState(null);

  const paymentUi = useSelector(paymentSelectors.getPaymentOrderUi);
  const loaded = useScript("https://checkout.razorpay.com/v1/checkout.js");

  const handlePayment = (response) => {
    response.type = paymentType;
    dispatch(verifyPayment({ payload: response }));
  };

  const makePayment = async (values) => {
    const paymentData = await dispatch(getPaymentOrder({ type: paymentType }));
    const paymentDetails = paymentData.payload;

    if (paymentDetails) {
      const options = {
        key: paymentDetails.key_id,
        amount: paymentDetails.amount, // Hence, 50000 refers to 50000 paise
        currency: paymentDetails.currency,
        name: "More & High",
        description: "Test Transaction",
        image: logo,
        order_id: paymentDetails.id,
        handler: handlePayment,
        prefill: {
          email: values.email,
          contact: values.phone,
        },
        theme: {
          color: "#348D15",
        },
      };

      var razorObj = new window.Razorpay(options);

      razorObj.on("payment.failed", function (response) {
        alert(response.error.code);
      });

      razorObj.open();
    }
  };

  return (
    <>
      <MetaTags title={"Payment"} />

      <div className={classes.root}>
        {!loaded ? (
          <div className={classes.loader}>
            <CircularProgress size="1.7rem" thickness={5} />
          </div>
        ) : (
          <div className={classes.paymentDiv}>
            {paymentType ? (
              <>
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "1.4rem",
                      fontWeight: 600,
                      margin: 0,
                    }}
                  >
                    Payment for{" "}
                    {paymentType === PAYMENT_TYPE.yearly
                      ? "Yearly"
                      : "Lifetime"}{" "}
                    plan.
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "0.9rem",
                      margin: 0,
                      marginTop: "0.25rem",
                    }}
                  >
                    Please enter phone number and email where you want to
                    recieve token when payment successful.
                  </p>
                </div>
                <Formik
                  onSubmit={makePayment}
                  initialValues={{ phone: "", email: "" }}
                  validationSchema={yup.object({
                    phone: yup
                      .string()
                      .required()
                      .matches(/^\d{10}$/, "Not a valid mobile number.")
                      .label("Phone Number"),
                    email: yup.string().required().email().label("Email ID"),
                  })}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <>
                      <Input
                        label="Phone Number"
                        value={values["phone"]}
                        name={"phone"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors["phone"]}
                        helperText={errors["phone"]}
                      />
                      <Input
                        label="Email Id"
                        value={values["email"]}
                        name={"email"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors["email"]}
                        helperText={errors["email"]}
                      />

                      <Button
                        type="submit"
                        classes={{ root: classes.sendButton }}
                        disabled={paymentUi.loading}
                        onClick={handleSubmit}
                      >
                        {paymentUi.loading ? (
                          <Box minWidth={200}>
                            <CircularProgress size="1.7rem" thickness={5} />
                          </Box>
                        ) : (
                          "Make Payment"
                        )}
                      </Button>
                    </>
                  )}
                </Formik>
              </>
            ) : (
              <div>
                {" "}
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "1.4rem",
                    fontWeight: 600,
                    margin: 0,
                  }}
                >
                  Please select a plan for Payment.
                </h1>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    classes={{ root: classes.sendButton }}
                    onClick={() => setPaymentType(PAYMENT_TYPE.yearly)}
                  >
                    Yearly
                  </Button>
                  <Button
                    classes={{ root: classes.sendButton }}
                    onClick={() => setPaymentType(PAYMENT_TYPE.five_yearly)}
                  >
                    Lifetime
                  </Button>
                </div>
                <Box textAlign="center" mt="2rem">
                  <Link style={{ fontSize: "0.8rem" }} to="/privacy-policy">
                    Teams and Conditions apply.
                  </Link>
                </Box>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
