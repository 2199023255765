import { useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyDY0EmfQhQ5jBj001pIUW6cKFTIODE_JHM", //"AIzaSyBdJVWMXV0ogdq76V4Gh4WdHOrkJDS7uTE",
  version: "weekly",
});

const MapContainer = ({ coordinates }) => {
  const mapRef = useRef();

  console.log(coordinates);

  useEffect(() => {
    if (!window) return;

    loader.load().then(() => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: coordinates,
        zoom: 15,
      });
      const marker = new window.google.maps.Marker({
        position: coordinates,
        map,
        title: "Your device's location!",
      });
      const infowindow = new window.google.maps.InfoWindow({
        content: `<p style="font-family:'Poppins';margin: 0;font-weight: 600;font-size: 0.9rem;color: #1f1f1f;">Your device's location!</p>`,
      });

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: coordinates }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            infowindow.setContent(
              `<p style="font-family:'Poppins';margin: 0;font-weight: 600;font-size: 0.9rem;color: #1f1f1f;">${results[0].formatted_address}</p>`
            );
            marker.setTitle(results[0].formatted_address);
            infowindow.open(map, marker);
          }
        }
      });

      const markerListener = () => {
        if (infowindow.getMap()) infowindow.close();
        else infowindow.open(map, marker);
      };
      marker.addListener("click", markerListener);

      return () => marker.removeListener(markerListener);
    });
  }, [coordinates]);

  return (
    <div
      ref={mapRef}
      style={{ height: "100vh", width: "100%", position: "relative" }}
    ></div>
  );
};

export default MapContainer;

// import {
//   Marker,
//   Popup,
//   TileLayer,
//   MapContainer as LeafletMapContainer,
// } from "react-leaflet";

// import "leaflet/dist/leaflet.css";
// import { Icon } from "leaflet";

// delete Icon.Default.prototype._getIconUrl;

// Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
//   iconUrl: require("leaflet/dist/images/marker-icon.png").default,
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
// });

// const MapContainer = ({ coordinates }) => {
//   const position = [coordinates.lat, coordinates.lng];

//   return (
//     <LeafletMapContainer
//       style={{ height: "100vh", zIndex: 1 }}
//       center={position}
//       zoom={18}
//       scrollWheelZoom={true}
//     >
//       <TileLayer
//         attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//       />
//       <Marker position={position}>
//         <Popup>Your location</Popup>
//       </Marker>
//     </LeafletMapContainer>
//   );
// };

// export default MapContainer;
