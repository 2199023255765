import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import MetaTags from "../../component/MetaTags";
import { AnimatedPage } from "../../component/UI";
import {
  Container,
  StyledTableCell,
  StyledTableRow,
} from "../../component/UI/styledItems";
import SearchIcon from "@material-ui/icons/Search";
import AdminTables from "../../component/UI/AdminTables";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import dateFormat from "../../utils/dateFormat";
import { useDispatch } from "react-redux";
import {
  getTokensAggregations,
  lockPhoneByAdmin,
  getAdminUserLockUnlock,
} from "../../../application/reducers/adminSlice";

const useStyles = makeStyles((theme) => ({
  root: {},
  lockUnlockPhone: {
    borderBottom: "1px solid #0D1E27",
    fontWeight: 500,
    fontSize: "1.5rem",
    display: "inline-block",
    color: "#0D1E27",
  },
  searchDiv: {
    display: "flex",
    alignItems: "center",
  },
  filterInputDiv: {
    border: "none",
    background: "white",
    borderRadius: 7,
    paddingLeft: "0.6rem",
    overflow: "hidden",
    width: "15rem",
    marginLeft: "auto",
    "& svg": {
      color: "#6A6A6A",
    },
    "& input": {
      marginLeft: 10,
      height: "2.5rem",
      fontFamily: "inherit",
      border: "none",
      outline: "none",
      width: "100%",
      color: "#6A6A6A",
      fontWeight: 500,
      "&::-webkit-input-placeholder": {
        color: "gray",
        fontWeight: 400,
      },
      "&:-ms-input-placeholder": {
        color: "gray",
        fontWeight: 400,
      },
      "&::placeholder": {
        color: "gray",
        fontWeight: 400,
      },
    },
  },
  tableCont: {
    padding: "0.8rem",
    paddingBottom: 0,
    marginTop: "1.5rem",
    background: "white",
    boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.2)",
  },
  tableInfoDiv: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      marginRight: "auto",
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#0D1E27",
    },
  },
  tableControllerButtons: {
    padding: 0,
    "& svg": {
      fontSize: "2rem",
      color: "#0D1E27",
    },
  },
  errorP: {
    color: "red",
    fontSize: "0.9rem",
    textAlign: "center",
  },
  loadingDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem 0",
  },
}));

const headData = [
  "#",
  "Activation Code",
  "Name",
  "Number",
  "Generation Date",
  "Status",
];

export default function LockUnlockPhone() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isTableEmpty, setIsTableEmpty] = useState(false);

  const [tokenAggrData, setTokenAggrData] = useState({});
  const [tokenData, setTokenData] = useState([]);
  const [error, setError] = useState("");

  const [tableBody, setTableBody] = useState([]);

  const tokenDataCount = useRef(0);
  const limit = 60;
  const docsToDisplay = 10;
  useEffect(() => {
    fetchData(null)
      .then((tokens) => {
        if (tokens.length === 0) setIsTableEmpty(true);
        setTableBody(tokens.slice(0, docsToDisplay));
      })
      .catch((err) => console.log(err));

    dispatch(getTokensAggregations())
      .then(({ payload }) => setTokenAggrData(payload.data))
      .catch((err) => console.log(err.message));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const searchingWithPhone = useRef(false);
  const [phoneNumber, _setPhoneNumber] = useState("");
  const setPhoneNumber = (e) => {
    const value = e.target.value;
    if (value.length > 10) return;
    _setPhoneNumber(value);

    if (value.length === 10 && Number(value)) {
      searchingWithPhone.current = true;

      setTableBody([]);
      setTokenData([]);
      tokenDataCount.current = 0;

      fetchData(null, value)
        .then((tokens) => {
          if (tokens.length === 0) setIsTableEmpty(true);
          setTableBody(tokens.slice(0, docsToDisplay));
        })
        .catch((err) => console.log(err));
    } else if (searchingWithPhone.current) {
      searchingWithPhone.current = false;
      // console.log("here searching");

      setTableBody([]);
      setTokenData([]);
      tokenDataCount.current = 0;

      fetchData(null)
        .then((tokens) => {
          if (tokens.length === 0) setIsTableEmpty(true);
          setTableBody(tokens.slice(0, docsToDisplay));
        })
        .catch((err) => console.log(err));
    }
  };

  const updateTokenStatus = (mainIndex, tableBodyIndex, loading, locked) => {
    const newTokenData = JSON.parse(JSON.stringify(tokenData));
    newTokenData[mainIndex].loading = loading;

    const newTableBody = JSON.parse(JSON.stringify(tableBody));
    newTableBody[tableBodyIndex].loading = loading;

    if (locked !== undefined) {
      newTokenData[mainIndex].locked = locked;
      newTableBody[tableBodyIndex].locked = locked;
    }

    setTokenData(newTokenData);
    setTableBody(newTableBody);
  };

  const lockUnlockPhone =
    (mainIndex, tableBodyIndex, tokenId, isLocked) => () => {
      updateTokenStatus(mainIndex, tableBodyIndex, true);

      dispatch(
        lockPhoneByAdmin({
          task: isLocked ? "unlock" : "lock",
          token: tokenId,
        })
      ).then(({ payload }) => {
        if (payload)
          updateTokenStatus(mainIndex, tableBodyIndex, false, !isLocked);
        else updateTokenStatus(mainIndex, tableBodyIndex, false);
      });
    };

  const fetchData = async (startAfter, phone) => {
    if (isTableEmpty) setIsTableEmpty(false);
    try {
      if (error.length !== 0) setError("");

      let { payload } = await dispatch(
        getAdminUserLockUnlock({ startAfter, limit, phone })
      );
      if (!payload) return;

      let tokens = payload.tokens;

      let i = tokenDataCount.current;
      tokens = tokens.map((token) => {
        return {
          ...token,
          i: ++i,
        };
      });

      tokenDataCount.current = tokenDataCount.current + tokens.length;
      setTokenData((tokenData) => tokenData.concat(tokens));
      return tokens;
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    }
  };

  const showPrevTableRecords = () => {
    const currentFirstIndex = tableBody[0].i;

    if (currentFirstIndex === 1) return; // Reached beginning of table

    console.log("end data");

    setTableBody(
      tokenData.slice(
        currentFirstIndex - docsToDisplay - 1,
        currentFirstIndex - 1
      )
    );
  };

  const showNextTableRecords = () => {
    const currentLastIndex = tableBody[tableBody.length - 1].i;
    const tokenDataLastIndex = tokenData[tokenData.length - 1].i;
    const recordsLeftBeforeLoadMoreData = 20;

    if (currentLastIndex === tokenData.length - recordsLeftBeforeLoadMoreData) {
      // time to load more data... will load data only once when we reach tokenData.length - recordsLeftBeforeLoadMoreData despite of how many time we click next
      if (tokenData.length !== tokenAggrData.total) {
        // there is more data left to load...
        const lastItemTokenId = tokenData[tokenData.length - 1].tokenId;
        // Sending network req to load more data...
        fetchData(lastItemTokenId);
        // console.log("load more data");
      }
    }

    if (currentLastIndex === tokenDataLastIndex) return; // reached end of data
    setTableBody(
      tokenData.slice(currentLastIndex, currentLastIndex + docsToDisplay)
    );
  };

  return (
    <>
      <MetaTags title={"Admin Lock/Unlock Phone"} />
      <AnimatedPage>
        <div style={{ background: "#F0F0F8" }}>
          <Container>
            <h2 className={classes.lockUnlockPhone}>Lock Unlock Phone</h2>
            <div className={classes.searchDiv}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.filterInputDiv}
              >
                <SearchIcon />
                <input
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  type="text"
                  placeholder="Search Phone Number"
                />
              </Box>
            </div>

            <div className={classes.tableCont}>
              <AdminTables
                headData={headData}
                bodyData={tableBody}
                phoneTable={true}
              >
                {tableBody.map((row, tableBodyIndex) => (
                  <StyledTableRow key={row.tokenId}>
                    <StyledTableCell>{row.i}</StyledTableCell>
                    <StyledTableCell>{row.tokenId}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                    <StyledTableCell>
                      {row.registeredAt && dateFormat(row.registeredAt)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.loading ? (
                        "Loading..."
                      ) : (
                        <span
                          onClick={lockUnlockPhone(
                            row.i - 1,
                            tableBodyIndex,
                            row.tokenId,
                            row.locked
                          )}
                          style={{
                            color: row.locked ? "#e80707" : "green",
                            cursor: "pointer",
                          }}
                        >
                          {row.locked ? "Locked" : "Unlocked"}
                        </span>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </AdminTables>
              {isTableEmpty ? (
                <p className={classes.errorP} style={{ color: "black" }}>
                  No data to show here.
                </p>
              ) : (
                tokenData.length === 0 &&
                !error && (
                  <div className={classes.loadingDiv}>
                    <CircularProgress
                      thickness={6}
                      size={30}
                      style={{ color: "gray" }}
                    />
                  </div>
                )
              )}
              {error.length !== 0 && (
                <p className={classes.errorP}>Failed to load data...</p>
              )}
              <div className={classes.tableInfoDiv}>
                <p>
                  Showing {tableBody[0]?.i}-{tableBody[tableBody.length - 1]?.i}{" "}
                  of {tokenAggrData.registered} Activation Codes
                </p>
                <IconButton
                  className={classes.tableControllerButtons}
                  onClick={showPrevTableRecords}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  className={classes.tableControllerButtons}
                  style={{ marginLeft: -3, marginRight: 5 }}
                  onClick={showNextTableRecords}
                >
                  <ChevronRightIcon />
                </IconButton>
              </div>
            </div>
          </Container>
        </div>
      </AnimatedPage>
    </>
  );
}
