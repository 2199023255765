import { Helmet } from "react-helmet-async";

const MetaTags = ({ title }) => {
  return (
    <Helmet title={title}>
      <meta
        name="description"
        content={"More and High - Think more, grow high"}
      />
      <meta
        property="og:description"
        content={"More and High - Think more, grow high"}
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://moreandhigh.com/" />
      <meta name="twitter:card" content="summary_large_image" />
      {/* <title>{title}</title> */}
    </Helmet>
  );
};

export default MetaTags;
