import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import queryString from "query-string";

import {
  Container,
  GrassImage,
  Heading,
  TeamsPaper,
} from "../component/UI/styledItems";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
import CustomTable from "../component/UI/CustomTable";
import PageLoading from "../component/UI/PageLoading";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchImages,
  fetchLocation,
  userSelectors,
} from "../../application/reducers/userSlice";

const useStyles = makeStyles((theme) => ({
  Details: {
    background: "#F0F0F8",
    position: "relative",
    paddingBottom: "6rem",
  },
  changeButton: {
    cursor: "pointer",
    transition: "0.3s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "15rem",
    [theme.breakpoints.down(700)]: {
      width: "auto",
    },
    "&:hover": {
      transform: "scale( 1.05 )",
    },
    "& h4": {
      color: "#1C232D",
      margin: 0,
      fontSize: "1.4rem",
      fontWeight: 600,
      [theme.breakpoints.down(670)]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.down(400)]: {
        fontSize: "1.1rem",
      },
    },
  },
  floatButton: {
    position: "fixed",
    bottom: "1rem",
    right: "1rem",
    marginLeft: "1rem",
    background: "black",
    width: "3.3rem",
    height: "3.3rem",
    borderRadius: "100%",
    zIndex: 199,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "rgb(121 121 121) 0px 0px 7px 2px",
    color: "white",
    overflow: "hidden",
    "& > span": {
      wordBreak: "break-all",
      fontSize: "0.9rem",
      userSelect: "all",
      marginLeft: "0.5rem",
    },
    "& > svg": {
      fontSize: "1.25rem",
    },
  },
  showLinkFloatButton: {
    width: "auto",
    height: "auto",
    borderRadius: "1rem",
    padding: "0.5rem",
    paddingRight: "1.5rem",
    // paddingTop: "1.5rem",
  },
}));

const Details = () => {
  const classes = useStyles();
  const routerLocation = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { show, uid } = queryString.parse(routerLocation.search);
  const isImages = show === "image";

  const toggleLocationState = () => {
    history.replace(
      `/details?show=${isImages ? "location" : "image"}&uid=${uid}`
    );
  };

  const userUiState = useSelector(userSelectors.getUserUi);
  const location = useSelector(userSelectors.getUserLocation);
  const images = useSelector(userSelectors.getUserImages);

  useEffect(() => {
    if (!uid) return;

    dispatch(fetchImages({ uid }));
    dispatch(fetchLocation({ uid }));
  }, [dispatch, uid]);

  return (
    <>
      <MetaTags title={"Details"} />
      <AnimatedPage>
        <PageLoading
          show={
            isImages
              ? userUiState.fetchImages.loading
              : userUiState.fetchLocation.loading
          }
        />
        <div className={classes.Details}>
          <Container>
            <Box display="flex" alignItems="center" marginBottom="4rem">
              <Heading color="#1C232D" margin="0 auto 0 0">
                Details
              </Heading>
              <TeamsPaper
                size="small"
                borderRadius="10px"
                padding="0.5rem 1.5rem"
                className={classes.changeButton}
                onClick={toggleLocationState}
              >
                <h4>Show {isImages ? "Location" : "Images"}</h4>
              </TeamsPaper>
            </Box>
            {!isImages
              ? location && <CustomTable locationData={location} />
              : images && <CustomTable imagesData={images} />}
          </Container>
          <GrassImage />
        </div>
      </AnimatedPage>
    </>
  );
};

export default Details;
