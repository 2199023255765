// import React, { useMemo } from "react";
// import { makeStyles, Toolbar, Button } from "@material-ui/core";
// import {
//   Container,
//   GrassImage,
//   Heading,
//   Paragraph,
// } from "../component/UI/styledItems";
// import queryString from "querystring";
// import MetaTags from "../component/MetaTags";
// import { AnimatedPage } from "../component/UI";
// import Map from "../component/MapContainer";
// import { useLocation, Link ,useHistory} from "react-router-dom";
// import { getAuth, } from "../../application/reducers/authSlice";
// import { useSelector } from "react-redux";
// import decode from "jwt-decode";
// const useStyles = makeStyles((theme) => ({
//   Location: {
//     background: "#F0F0F8",
//     position: "relative",
//     paddingBottom: "6rem",
//   },
// }));

// const Location = () => {
//   const classes = useStyles();
//   const location = useLocation();
//   const history = useHistory();
//   const authState = useSelector(getAuth);

//   const { lat, lng, name } = queryString.decode(location.search.slice(1));
//   const { uid } = queryString.parse(location.search);
//   const toggleLocationState = () => {
//     history.replace(
//       `/details?show=$image&uid=${uid}`
//     );
//   };
// //
// // const { uid } = useMemo(() => decode(authState.token), [authState]);
// // const showImages = () => history.push(`/details?show=image&uid=${uid}`);

//   return (
//     <>
//       <MetaTags title={"More & High - Location"} />
//       <AnimatedPage>
//         <div className={classes.Location}>
//           <Container>
//             <Toolbar>
//               <Heading style={{ border: "none", flexGrow: '1' }} color="#1C232D" margin="0">
//                 Hello,
//               </Heading>
//               {/* <Link to="/details" style={{ textDecoration: 'none' }}> */}
//                 <Button onClick={toggleLocationState} variant="contained" style={{
//                   background: '#fff', color: '#1C232D',
//                   boxShadow: "rgb(121 121 121) 0px 0px 7px 0px",
//                 }}>
//                   <b>Show Images</b>
//                 </Button>
//               {/* </Link> */}
//             </Toolbar>
//             <Paragraph margin="5px 0">
//               <span style={{ textTransform: "none" }}>{name}</span>, your phone
//               has been tracked on this location.
//             </Paragraph>

//             <div
//               style={{ height: "100vh", width: "100%", position: "relative" }}
//             >
//               {<Map coordinates={{ lat: Number(lat), lng: Number(lng) }} />}
//             </div>
//           </Container>
//           <GrassImage />
//         </div>
//       </AnimatedPage>
//     </>
//   );
// };

// export default Location;

import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  Container,
  GrassImage,
  Heading,
  Paragraph,
} from "../component/UI/styledItems";
import queryString from "querystring";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
import Map from "../component/MapContainer";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  Location: {
    background: "#F0F0F8",
    position: "relative",
    paddingBottom: "6rem",
  },
}));

const Location = () => {
  const classes = useStyles();
  const location = useLocation();

  const { lat, lng, name } = queryString.decode(location.search.slice(1));

  return (
    <>
      <MetaTags title={"More & High - Location"} />
      <AnimatedPage>
        <div className={classes.Location}>
          <Container>
            <Heading style={{ border: "none" }} color="#1C232D" margin="0">
              Hello,
            </Heading>
            <Paragraph margin="5px 0">
              <span style={{ textTransform: "none" }}>{name}</span>, your phone
              has been tracked on this location.
            </Paragraph>

            <div
              style={{ height: "100vh", width: "100%", position: "relative" }}
            >
              {<Map coordinates={{ lat: Number(lat), lng: Number(lng) }} />}
            </div>
          </Container>
          <GrassImage />
        </div>
      </AnimatedPage>
    </>
  );
};

export default Location;
