import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import {
  Container,
  GrassImage,
  Heading,
  Paragraph,
} from "../component/UI/styledItems";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
const useStyles = makeStyles((theme) => ({
  career: {
    background: "#DCF8D2",
    position: "relative",
    overflow: "hidden",
    paddingBottom: "4rem",
  },
  aboutUsContainer: {
    display: "flex",
    alignItems: "center",
  },
  careerBlob: {
    background: `url(/career/textBlob.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  textDiv: {
    width: "30rem",
    position: "relative",
    zIndex: 10,
    [theme.breakpoints.down(1000)]: {
      width: "37rem",
    },
    [theme.breakpoints.down(670)]: {
      textAlign: "center",
    },
    "& h2": {
      margin: "0 0 1.5rem",
      color: "#0D1E27",
      "& > span": {
        whiteSpace: "nowrap",
        display: "block",
      },
    },
    "& p": {
      margin: 0,
      color: "#0D1E27",
    },
  },
  rightImage: {
    width: "36rem",
    margin: "auto",
    position: "relative",
    zIndex: 5,
    transition: "width 0.5s",
    [theme.breakpoints.down(1290)]: {
      width: "26rem",
    },
    [theme.breakpoints.down(1000)]: {
      display: "none !important",
    },
  },
  blobBottom: {
    position: "absolute",
    width: "11rem",
    bottom: "4rem",
    right: "0",
    zIndex: 1,
    display: "none",
    [theme.breakpoints.down(1000)]: {
      display: "block !important",
    },
    [theme.breakpoints.down(670)]: {
      bottom: "-8rem",
      right: "0",
      transform: "rotate(90deg)",
    },
  },
  button: {
    fontWeight: "600",
    fontSize: "1.5rem",
    textTransform: "capitalize",
    padding: "0.9rem 3rem",
    background: "#FFD234",
    borderRadius: 10,
    color: "#0D1E27",
    marginTop: "2.5rem",
    letterSpacing: 0,
    [theme.breakpoints.down(670)]: {
      fontSize: "1.2rem",
      padding: "0.9rem 2.5rem",
    },
    "&:hover": {
      background: "#FFD234",
    },
  },
}));

const Career = () => {
  const classes = useStyles();
  return (
    <>
      <MetaTags title={"Career"} />
      <AnimatedPage>
        <div className={classes.career}>
          <Container className={classes.aboutUsContainer}>
            <div className={classes.textDiv}>
              <Heading>
                <span>
                  Start A{" "}
                  <span className={classes.careerBlob}>
                    Career,&nbsp;&nbsp;
                  </span>
                </span>{" "}
                <span>Hire A Star</span>
              </Heading>
              <Paragraph>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ullam
                nobis nulla alias repellendus quibusdam minima esse praesentium
                nisi animi quis.
              </Paragraph>
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href="https://forms.gle/gUiSKyKYXvzVAcrj6"
                target="_blank"
                rel="norefferer noreferrer"
              >
                <Button color="inherit" classes={{ root: classes.button }}>
                  Apply Now  
                  <img
                    style={{ marginLeft: 15 }}
                    src="/career/rightIcon.svg"
                    alt=""
                  />
                </Button>
              </a>
            </div>
            <div className={classes.rightImage}>
              <img
                style={{ width: "100%" }}
                src={require("../Assets/new/carrier.png").default}
                alt=""
              />
            </div>
          </Container>
          <img
            className={classes.blobBottom}
            src={require("../Assets/blobBottom.svg").default}
            alt=""
          />
          {/* <GrassImage /> */}
        </div>
      </AnimatedPage>
    </>
  );
};

export default Career;
